import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import ProjectPage from "./pages/ProjectPage";
import ArtObjectPage from "./pages/ArtObjectPage";
import RouteWithLayout from "./components/RouteWithLayout";
import { Main as MainLayout } from "./layouts";

import SettingsPage from "./pages/SettingsPage";
import AddArtObjectPage from "./pages/artObjects/AddArtObjectPage";
import AddProjectPage from "./pages/AddProjectPage";
import ArtObjectDetail from "./pages/ArtObjectDetail";
import LogoutPage from "./pages/LogoutPage";
import LoginPage from "./pages/LoginPage";
import history from "./history";
import Edit from "./pages/artObjects/Edit";
import UsersPage from "./pages/backoffice/UsersPage";
import EditCollection from "./pages/collection/Edit";
import HomeScreen from "./pages/HomeScreen";

let Routes: React.FC<{}> = (props: any) => {
  let routes = [
    <RouteWithLayout
      key="artobject.new"
      path="/app/collections/:projectId/artobjects/new"
      component={AddArtObjectPage}
      layout={MainLayout}
    />,
    <RouteWithLayout
      key="artobject.new"
      path="/app/collections/:projectId/artobjects/:artObjectId/edit"
      component={Edit}
      layout={MainLayout}
    />,
    <RouteWithLayout
      key="artobject.detail"
      path="/app/collections/:projectId/artobjects/:artObjectId"
      component={ArtObjectDetail}
      layout={MainLayout}
    />,
    <RouteWithLayout
      key="artobject.overview"
      path="/app/collections/:projectId/artobjects"
      component={ArtObjectPage}
      layout={MainLayout}
    />,
    <RouteWithLayout
      key="artobject.new"
      path="/app/collections/:projectId/edit"
      component={EditCollection}
      layout={MainLayout}
    />,
    <RouteWithLayout
      key="collections.new"
      path="/app/collections/new"
      component={AddProjectPage}
      layout={MainLayout}
    />,

    <RouteWithLayout
      key="collections"
      path="/app/collections"
      component={ProjectPage}
      layout={MainLayout}
    />,
    <RouteWithLayout
      key="settings"
      path="/app/settings"
      component={SettingsPage}
      layout={MainLayout}
    />,
    <RouteWithLayout
      key="users"
      path="/app/users"
      component={UsersPage}
      layout={MainLayout}
    />,
    <Route key={"login"} path={"/login"} component={LoginPage} />,
    <RouteWithLayout
      key="logout"
      path="/app/logout"
      component={LogoutPage}
      layout={MainLayout}
    />,
    <Route key={"home"} exact path={"/home"} component={HomeScreen} />,
  ];

  return (
    <Router history={history}>
      <Switch>
        <Route key={"home"} exact path={"/"} component={HomeScreen} />
        <Redirect to={"/app/collections"} from={"/app"} exact />
        {props.children}
        <Switch>{routes}</Switch>
      </Switch>
    </Router>
  );
};

export default Routes;
