import {
  RECEIVE_ART_OBJECT,
  RECEIVE_ART_OBJECTS,
  RECEIVE_EXPORT_LABELS,
  REDIRECT_ARTOBJECT,
  REQUEST_ART_OBJECT,
  REQUEST_ART_OBJECTS,
  REQUEST_EXPORT_LABELS
} from "../actions/artObjectActions";

function artObjects(
  state = {
    isFetching: false,
    isFetchingLabels: false,
    items: [],
    redirect: null
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_ART_OBJECTS:
      return Object.assign({}, state, {
        isFetching: true,
        redirect: null
      });
    case RECEIVE_ART_OBJECTS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.artObjects,
        lastUpdated: action.lastUpdated
      });
    case REQUEST_ART_OBJECT:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_ART_OBJECT:
      return Object.assign({}, state, {
        isFetching: false,
        artObject: action.artObject
      });
    case REQUEST_EXPORT_LABELS:
      return Object.assign({}, state, {
        isFetchingLabels: true
      });
    case RECEIVE_EXPORT_LABELS:
      return Object.assign({}, state, {
        isFetchingLabels: false
      });
    case REDIRECT_ARTOBJECT:
      return Object.assign({}, state, {
        redirect: "/app/collections/:projectId/artObjects"
      });
    default:
      return state;
  }
}

export default artObjects;
