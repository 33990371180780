import React, { Dispatch } from "react";
import { fetchToken } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import LoginFormBase from "./LoginFormBase";
import { useTokenStore } from "../../stores/useTokenStore";
import { Redirect } from "react-router-dom";

interface IProps {
  dispatch: Dispatch<any>;
}

const LoginForm = (props: IProps) => {
  let redirect = null;

  if (useTokenStore().refreshToken) {
    redirect = <Redirect to={"/"} />;
  }

  const handleSubmit = (values: any) => {
    props.dispatch(fetchToken(values.username, values.password));
  };

  return (
    <>
      {redirect}
      <LoginFormBase onSubmit={handleSubmit} />
    </>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(LoginForm);
