import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { Delete, UploadFile } from "@mui/icons-material";
import { useQuery } from "react-query";
import IArtObject from "../../types/artObject";
import { apiGet } from "../../api/api";
import Loader from "./Loader";
import Dropzone from "react-dropzone";
import ConvertImage from "./ConvertImage";

interface IProps {
  onChange: any;
  previewUrl: any;
  artObject?: IArtObject;
}

const ImageUpload = (props: IProps) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [imageConvertedUrl, setImageConvertedUrl] = useState<any>();

  useQuery(
    `image${props.artObject && props.artObject.id}`,
    async () => {
      const response = await apiGet(
        `/projects/${
          props.artObject && props.artObject.projectId
        }/art_objects/${props.artObject && props.artObject.id}/images`,
        false
      );
      return await response.text();
    },
    {
      onSuccess: (url) => {
        if (url) {
          handleConvertedImage(url);
          setImagePreviewUrl(url);
        }
      },
      enabled:
        props.artObject &&
        props.artObject.projectId != null &&
        props.previewUrl === undefined,
      refetchOnMount: "always",
    }
  );

  const _handleImageChange = (files: any) => {
    let file = files[0];
    if (!file) return;

    setLoading(true);

    // if (file.type === "image/tiff") {
    //   handleTiffFile(file);
    // } else {
    handleImageFile(file);
    // }
  };

  // const handleTiffFile = (file: File) => {
  //   let reader = new FileReader();
  //   reader.onloadend = () => {
  //     const arrayBuffer = reader.result;
  //     const tiff = new Tiff({ buffer: arrayBuffer });
  //     const canvas = tiff.toCanvas();
  //     const img = canvas.toDataURL("image/jpeg");
  //     setFile(file);
  //     setImagePreviewUrl(img);
  //     setLoading(false);
  //   };
  //
  //   reader.readAsArrayBuffer(file);
  // };

  const handleImageFile = (file: File) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      // setFile(file);
      setImagePreviewUrl(reader.result);
      setLoading(false);
    };
    reader.readAsDataURL(file);
  };

  const handleConvertedImage = (url: any) => {
    console.log("converted,", url);
    setImageConvertedUrl(url);
    props.onChange(url, imagePreviewUrl);
  };

  if (props.previewUrl && !imagePreviewUrl) {
    setImagePreviewUrl(props.previewUrl);
  }

  let $imageConverted = null;

  if (imageConvertedUrl) {
    $imageConverted = (
      <img
        style={{ maxWidth: 500, maxHeight: 300 }}
        src={imageConvertedUrl}
        alt="Could not be converted"
      />
    );
  }

  return (
    <>
      <Box display={"flex"} justifyContent={"center"}>
        {imagePreviewUrl ? (
          <Button
            color="primary"
            aria-label="Remove picture"
            data-cy={"remove-picture-button"}
            onClick={() => {
              props.onChange("", "");
              setImagePreviewUrl(null);
              setImageConvertedUrl(null);
            }}
          >
            Foto verwijderen{" "}
            <Delete color="primary" style={{ marginLeft: "10px" }} />
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        {imagePreviewUrl ? (
          ""
        ) : (
          <Dropzone
            onDrop={(acceptedFiles) => {
              _handleImageChange(acceptedFiles);
            }}
            accept={"image/*"}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <Box
                  {...getRootProps()}
                  style={{
                    textAlign: "center",
                    border: "dashed",
                    borderColor: "#eeeeee",
                    borderRadius: "4px",
                    padding: "40px 60px",
                    cursor: "pointer",
                  }}
                  data-cy={"dropzone"}
                >
                  <input {...getInputProps()} />
                  <UploadFile fontSize={"large"} />
                  <p>Klik om een foto te selecteren</p>
                </Box>
              </section>
            )}
          </Dropzone>
        )}
        {loading ? <Loader open={true} /> : ""}
        <div style={{ display: "none", margin: 10 }}>
          {imagePreviewUrl ? (
            <ConvertImage
              image={imagePreviewUrl}
              onConversion={handleConvertedImage}
              format="jpeg"
              style={{ maxWidth: 700, maxHeight: 500 }}
              hidden
            />
          ) : (
            ""
          )}
        </div>
        {$imageConverted}
      </Box>
    </>
  );
};

export default ImageUpload;
