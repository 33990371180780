import { Dispatch } from "react";
import { useTokenStore } from "../../stores/useTokenStore";
import { apiBaseUrl } from "../../constants";

export const REQUEST_TOKEN = "REQUEST_TOKEN";
export const RECEIVE_TOKEN = "RECEIVE_TOKEN";
export const TOKEN_ERROR = "TOKEN_ERROR";
export const SIGN_OUT = "SIGN_OUT";

export function requestToken() {
  return {
    type: REQUEST_TOKEN
  };
}

export function signOut() {
  return {
    type: SIGN_OUT
  };
}

function receiveToken(token: string) {
  return {
    type: RECEIVE_TOKEN,
    token
  };
}

function tokenError() {
  return {
    type: TOKEN_ERROR
  };
}

export function tokenFromSession() {
  return (dispatch: Dispatch<any>) => {
    const token = sessionStorage.getItem("Authorization");
    if (token !== null) {
      dispatch(receiveToken(token));
    }
  };
}

export function fetchToken(username: string, password: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestToken());

    return fetch(`${apiBaseUrl}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        password
      })
    })
      .then(response => response.json())
      .then(data => {
        try {
          fetch(`${apiBaseUrl}/users/refresh_token`, {
            headers: {
              Authorization: data.Authorization,
              "Content-Type": "application/json",
              Accepts: "application/json"
            }
          })
            .then(r => r.json())
            .catch(() => {})
            .then((refreshResponse: any) => {
              if (!refreshResponse) {
                return dispatch(tokenError());
              }
              useTokenStore.getState().setTokens({
                accessToken: data.Authorization,
                refreshToken: refreshResponse.refreshToken
              });
              dispatch(receiveToken(data.Authorization));
            });
        } catch (error) {
          dispatch(tokenError());
        }
      })
      .catch(() => {
        dispatch(tokenError());
      });
  };
}
