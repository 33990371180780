import React, { useState } from "react";
import { useModalStore } from "../../../../stores/useModalStore";
import history from "../../../../history";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

function OptionsCell({ value }: any) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeCollection = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
    useModalStore.setState({
      changeCollectionModal: {
        open: true,
        data: {
          artObjectId: value.split(";")[0],
          projectId: value.split(";")[1],
          refresh: () => {
            history.push(`/app/collections/${value.split(";")[1]}/artObjects`);
          }
        }
      }
    });
  };
  return (
    <>
      <IconButton color="primary" size="medium" onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          id={value.split(";")[0] + ""}
          onClick={handleChangeCollection}
        >
          Wijzig collectie
        </MenuItem>
      </Menu>
    </>
  );
}

export default OptionsCell;
