import React, { useState } from "react";
import { Breadcrumbs, Card, CardContent } from "@mui/material";
import strings from "../../languages/strings";
import { fetchArtObjects } from "../../redux/actions/artObjectActions";
import IArtObject from "../../types/artObject";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Wizard } from "../../components/artObjects";
import { makeStyles } from "@mui/styles";
import { useQuery } from "react-query";
import { apiGet, apiPost, apiPostForm } from "../../api/api";
import { loadCurrentUser } from "../../api/Users";
import { dataURItoBlob } from "../../components/utils/imageUtils";
import history from "../../history";
import Loader from "../../components/utils/Loader";
import IArtchiveError, { ArtchiveErrorCodes } from "../../types/artchiveError";
import Toast from "../../components/utils/Toast";
import { FORM_ERROR } from "final-form";

interface IProps {
  artObject: IArtObject;
  match?: any;
  classes: any;
  dispatch: any;
  redirect: any;
}

const AddArtObjectPage = (props: IProps) => {
  const {
    match: { params },
  } = props;

  const classes = useStyles();
  const [nextId, setNextId] = useState<any>(null);
  const [defaultMaker, setDefaultMaker] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [taxationNumberExists, setTaxationNumberExists] = useState(false);

  useQuery(
    "infoForNewArtobject",
    async () => {
      setLoading(true);
      return await apiGet(`/projects/${params.projectId}/art_objects/new`);
    },
    {
      onSuccess: (data) => {
        setNextId(data.nextId);
        setDefaultMaker(data.maker);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
      refetchOnMount: "always",
    }
  );

  const submit = async (artObject: IArtObject, imageData: any) => {
    artObject.projectId = props.match.params.projectId;
    setLoading(true);
    try {
      var json: any = await apiPost(
        `/projects/${artObject.projectId}/art_objects`,
        artObject
      );
      if (imageData && imageData.length > 0) {
        const formData: FormData = new FormData();
        formData.append("id", json.id);
        formData.append("imageData", dataURItoBlob(imageData));

        apiPostForm(
          `/projects/${artObject.projectId}/art_objects/${json.id}/images/form`,
          formData
        )
          .then(() => {
            props.dispatch(fetchArtObjects(artObject.projectId));
            setLoading(false);
            history.push(`/app/collections/${artObject.projectId}/artObjects`);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      } else {
        setLoading(false);
        history.push(`/app/collections/${artObject.projectId}/artObjects`);
      }
      loadCurrentUser();
    } catch (e: any) {
      setLoading(false);
      try {
        const error: IArtchiveError = await e.json();
        if (ArtchiveErrorCodes.TAXATION_NUMBER_EXISTS == error.code) {
          setTaxationNumberExists(true);
        }
        return { [FORM_ERROR]: "Inventarisnr bestaat al" };
      } catch (e) {
        console.error("Not a server error", e);
      }
    }
    return {};
  };

  return (
    <div className={classes.root}>
      <Loader open={isLoading} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: "black" }} to="/app/collections">
          {strings.collections}
        </Link>
        <Link
          style={{ color: "black" }}
          to={`/app/collections/${params.projectId}/artObjects`}
        >
          {strings.artObject}
        </Link>
        <Link
          style={{ color: "black" }}
          to={`/app/collections/${params.projectId}/artObjects/new`}
        >
          {strings.newArtObject}
        </Link>
      </Breadcrumbs>

      <Card className={classes.content}>
        <CardContent>
          <Wizard
            initialValues={{
              taxationNumber: nextId,
              maker: defaultMaker,
            }}
            onSubmit={submit}
          />
        </CardContent>
      </Card>
      <Toast
        text={"Inventarisnr bestaat al in de collectie."}
        open={taxationNumberExists}
        handleClose={() => {
          setTaxationNumberExists(false);
        }}
        error
        id={"taxation-number-exists"}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
}));

function mapStateToProps(state: any) {
  const { artObjects } = state;
  const { redirect } = artObjects || {
    redirect: null,
  };

  return {
    redirect,
  };
}

export default connect(mapStateToProps)(AddArtObjectPage);
