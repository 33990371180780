import React, { useEffect, useState } from "react";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { Box, Button, Checkbox } from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import PaginationControls from "./PaginationControls";
import ImageCell from "./cells/ImageCell";
import OptionsCell from "./cells/OptionsCell";
import DateCell from "./cells/DateCell";
import DateTimeCell from "./cells/DateTimeCell";
import { useModalStore } from "../../../stores/useModalStore";

function Table({
  columns,
  data,
  rowClick,
  search,
  objectType: filter,
  label,
  startPage,
  onPageSwitch,
  initialState,
}: any) {
  const [firstDataItem] = data;
  const projectId = firstDataItem?.projectId || null;
  const [showSelectedRows, setShowSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const {
    getTableProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    nextPage,
    pageCount,
    gotoPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: initialState
        ? initialState
        : {
            pageSize: 25,
            filters:
              filter && filter.objectType
                ? [
                    {
                      id: "objectType",
                      value: filter?.objectType,
                    },
                  ]
                : [],
            globalFilter: search,
          },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    // Add useRowSelect hook for row selection
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              onClick={(e) => {
                e.stopPropagation();
                const selectedRow = row.original;
                // @ts-ignore
                setSelectedRows((prevSelectedRows) => {
                  // @ts-ignore
                  if (prevSelectedRows.includes(selectedRow.id)) {
                    // Remove the id if it's already in the array
                    return prevSelectedRows.filter(
                      // @ts-ignore
                      (id) => id !== selectedRow.id
                    );
                  } else {
                    // Add the id to the array
                    // @ts-ignore
                    return [...prevSelectedRows, selectedRow.id];
                  }
                });
              }}
            />
          ),
        },
        ...columns,
      ]);
    }
  );
  const filteredRows = showSelectedRows
    ? page.filter((row) => selectedRowIds[row.id])
    : page;

  const handleExportModalClickOpen = () => {
    console.log(selectedRows);
    useModalStore.setState({
      exportArtObjectModal: {
        open: true,
        data: {
          projectId,
          selectedRows,
        },
      },
    });
  };

  useEffect(() => {
    if (startPage > 0 && startPage < pageCount) {
      setTimeout(() => {
        gotoPage(() => startPage);
      }, 200);
    }
  }, [startPage, gotoPage, pageCount]);

  useEffect(() => {
    setPageSize(25);

    if (filter && filter.objectType === "") {
      setFilter("objectType", null);
    } else if (filter) {
      setFilter("objectType", filter.objectType);
    }
    if (search === "") {
      setGlobalFilter(null);
    } else {
      setGlobalFilter(search);
    }
  }, [search, filter, setFilter, setGlobalFilter, setPageSize]);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        color={"#4c4c4c"}
        paddingTop={2}
        fontSize={13}
      >
        <Box paddingLeft={2}>
          {rows.length !== data.length
            ? `${rows.length} van ${data.length} ${label}`
            : `${data.length} ${label}`}
        </Box>
        <PaginationControls
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          pageCount={pageCount}
          previousPage={previousPage}
          pageIndex={pageIndex}
          onPageSwitch={onPageSwitch ? onPageSwitch : null}
        />
      </Box>
      <Box paddingLeft={1}>
        <Button onClick={() => setShowSelectedRows(!showSelectedRows)}>
          {showSelectedRows ? "Bekijk alle kunstwerken" : "Bekijk selectie"}
        </Button>
      </Box>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <TableSortLabel
                    active={column.isSorted}
                    direction={column.isSortedDesc ? "desc" : "asc"}
                    style={{ fontWeight: 600, opacity: 0.8 }}
                  >
                    {column.render("Header")}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {filteredRows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                {...row.getRowProps()}
                onClick={() => rowClick(row)}
              >
                {row.cells.map((cell) => (
                  <TableCell
                    data-cy={`table-cell-${cell.column.id}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        color={"#4c4c4c"}
        paddingBottom={2}
        fontSize={13}
      >
        <Box paddingLeft={2}></Box>
        <PaginationControls
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          pageCount={pageCount}
          previousPage={previousPage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          onPageSwitch={onPageSwitch}
        />
      </Box>
    </>
  );
}

export default Table;
export { ImageCell, OptionsCell, DateCell, DateTimeCell };
