import React, { useState } from "react";
import {
    Box,
    Button, Checkbox,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import strings from "../languages/strings";
import { useModalStore } from "../stores/useModalStore";
import { connect } from "react-redux";
import ModalDialog from "../components/utils/ModalDialog";
import { makeStyles } from "@mui/styles";
import { useMutation } from "react-query";
import { apiGet } from "../api/api";
import Loader from "../components/utils/Loader";

const useStyles = makeStyles((theme: any) => ({
  buttonColor: {
    width: "50%",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.hover,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const ExportArtObjectModal = () => {
  const classes = useStyles();
  const {
    exportArtObjectModal: { open, data },
  } = useModalStore();

  const [exportType, setExportType] = useState<any>("LIST");
  const [selection, setSelection] = useState<any>("ALL");

  const handleTypeChange = (event: SelectChangeEvent) => {
    setExportType(event.target.value as string);
  };

  const close = () => {
    setExportType("LIST");
    setSelection("ALL");
    useModalStore.setState({ exportArtObjectModal: { open: false, data: {} } });
  };

  const { isLoading, mutate } = useMutation(
    async (values: any) => {
      const search = selection == "SEARCH" ? data.search : "";
      const objectType = selection == "SEARCH" ? data.objectType : "";
      let extraParams = `?type=${exportType}&search=${search}&objectType=${objectType}`;

      if (!!data.selectedRows) {
          const artObjectIdsParam = `&artObjectIds=${data.selectedRows}`;
          extraParams += artObjectIdsParam;
      }

      const response = await apiGet(
            `/projects/${data.projectId}/art_objects/export${extraParams}`,
            false
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `artchive_etiket_export.pdf`); // 3. Append to html page
      document.body.appendChild(link); // 4. Force download
      link.click(); // 5. Clean up and remove the link
      //@ts-ignore
      link.parentNode.removeChild(link);
    },
    {
      onSuccess: close,
    }
  );

  // const handleExport = () => {
  //   const search = selection !== "ALL" ? data.search : "";
  //   const objectType = selection !== "ALL" ? data.objectType : "";
  //
  //   props.dispatch(exportArtObjectLabels(data.projectId, exportType, search, objectType));
  // };

  return (
    <ModalDialog
      open={open}
      onClose={close}
      title={strings.export}
      actions={
        <>
          <Button onClick={close}>{strings.cancel}</Button>
          <Button onClick={mutate} color="primary">
            {strings.export}
          </Button>
        </>
      }
    >
      <Loader open={isLoading} />
      <DialogContentText sx={{ marginBottom: 2 }}>
        {strings.explanationArtObjectExport}
      </DialogContentText>
      <Box>
        <FormControl variant={"outlined"} fullWidth>
          <InputLabel id={"exportLabel"}>Type export</InputLabel>
          <Select
            labelId={"exportLabel"}
            label={"Type export"}
            value={exportType}
            onChange={handleTypeChange}
            variant={"outlined"}
            fullWidth
          >
            <MenuItem value={"LIST"}>Lijst</MenuItem>
            <MenuItem value={"LABEL"}>Labels 2x2</MenuItem>
          </Select>
        </FormControl>
          <div
              aria-label="text alignment"
              style={{ width: "100%", marginTop: "1rem" }}
              color={"success"}
              className="flex justify-content-between align-items-center"
          >
              <Checkbox
                  autoFocus
                  onChange={() => {
                      setSelection("ALL");
                  }}
                  checked={selection === "ALL"}
              />
              <span>Volledige collectie</span>
              <Checkbox
                  onChange={() => {
                      setSelection("SELECTION");
                  }}
                  checked={selection === "SELECTION"}
                  disabled={(!data || (data.selectedRows?.length < 1))}
              />
              <span>Selectie</span>
              <Checkbox
                  disabled={(!data || (!data.search && !data.objectType))}
                  onChange={() => {
                      setSelection("SEARCH");
                  }}
                  checked={selection === "SEARCH"}
              />
              <span>Huidige zoekopdracht</span>
          </div>
      </Box>
    </ModalDialog>
  );
};

export default connect(() => ({}))(ExportArtObjectModal);
