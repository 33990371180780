import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import Loader from "../components/utils/Loader";
import { Link, useParams } from "react-router-dom";
import ResetPasswordForm from "../components/authentication/resetPasswordForm/ResetPasswordForm";
import { apiPost } from "../api/api";

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
  match?: any;
}

function ResetPasswordPage(props: IProps) {
  const { isFetching, match } = props;
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const { locale }: any = useParams();

  const handleSubmit = (values: any) => {
    const token = match.params.token;
    const email = match.params.email;
    apiPost("/users/resetpassword", {
      email,
      password: values.password1,
      token,
    })
      .then(() => {
        setMessage("Wachtwoord opgeslagen, uw kunt nu inloggen");
        setSuccess(true);
      })
      .catch((reason: any) => {
        console.error(reason);
        setMessage("Reset link is niet langer geldig");
      });
  };

  return (
    <Fragment>
      <Box paddingTop={20}>
        <Container maxWidth="md" className="pd-5">
          <Grid container spacing={4} style={{ justifyContent: "center" }}>
            <Grid item lg={6}>
              <Loader open={isFetching} />
              <div className="border-0">
                <div className="card-body px-lg-5 py-lg-5 center">
                  <Typography variant="h4" className="mt-2 mb-4">
                    Artchive
                  </Typography>
                  <Typography>{message}</Typography>
                  {success ? (
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <Link
                        to={`/${locale}/Dashboard`}
                        style={{ margin: "auto", marginTop: 20 }}
                      >
                        Ga naar de Artchive app
                      </Link>
                    </div>
                  ) : (
                    <>
                      <ResetPasswordForm handleSubmit={handleSubmit} />
                      <div style={{ display: "flex", textAlign: "center" }}>
                        <Link
                          to={`/${locale}/Dashboard`}
                          style={{ margin: "auto", marginTop: 20 }}
                        >
                          Artchive
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
}

function mapStateToProps(state: any) {
  const { CHANGE_TO_REDUCER_NAME } = state;
  const { isFetching, error, success } = CHANGE_TO_REDUCER_NAME || {
    isFetching: false,
    error: null,
    success: false,
  };
  return {
    isFetching,
    errorMessage: error,
    success,
  };
}

export default connect(mapStateToProps)(ResetPasswordPage);
