import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

import { Footer, Sidebar, Topbar } from "./components";
import { useTokenStore } from "../../stores/useTokenStore";
import { useQuery } from "react-query";
import { useCurrentUserStore } from "../../stores/useCurrentUserStore";
import { apiGet } from "../../api/api";
import Loader from "../../components/utils/Loader";
import ReactGA from "react-ga";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: "100%"
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleSignOut = () => {
    useTokenStore
      .getState()
      .setTokens({ accessToken: null, refreshToken: null });
    window.location.reload();
  };

  const { isLoading } = useQuery(
    "currentUser",
    async () => {
      return await apiGet("/users/current");
    },
    {
      onSuccess: data => {
        ReactGA.set({
          userId: data.id
        });
        useCurrentUserStore.setState({ user: data });
      }
    }
  );

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Loader open={isLoading} />
      <Topbar onSidebarOpen={handleSidebarOpen} onSignOut={handleSignOut} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
