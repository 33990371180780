import React, { Component } from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import ChangePasswordForm from "../components/settings/ChangePasswordForm";
import { withStyles } from "@mui/styles";

interface IProps {
  classes: any;
}

interface IState {}

const useStyles = (theme: any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    martingTop: theme.spacing(2)
  }
});

class SettingsPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Card className="p-4 mb-4">
                <CardContent>
                  <Box fontWeight="fontWeightBold">Wachtwoord wijzigen</Box>
                  <ChangePasswordForm />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(SettingsPage);
