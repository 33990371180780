import React from "react";
import { Button, Grid } from "@mui/material";
import UserList from "../../components/users/UserList";
import { makeStyles } from "@mui/styles";
import { useModalStore } from "../../stores/useModalStore";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

function UsersPage() {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            onClick={() => {
              useModalStore.setState({
                addUserModal: {
                  open: true,
                },
              });
            }}
          >
            Gebruiker toevoegen
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} lg={12}>
        <UserList />
      </Grid>
    </Grid>
  );
}

export default UsersPage;
