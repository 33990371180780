import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import ProjectToolbar from "../components/collections/ProjectToolbar";
import ProjectList from "../components/collections/ProjectList";

interface IProps {
  classes: any;
}

interface IState {
  search: string;
}

const useStyles = (theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
});

class ProjectPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      search: "",
    };
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ProjectToolbar onChange={this.handleSearchChange} />
        <div className={classes.content}>
          <ProjectList search={this.state.search} />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(ProjectPage);
