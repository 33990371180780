import React from "react";
import ProjectFormBase from "./ProjectFormBase";
import { addProject } from "../../redux/actions/projectActions";

const ProjectForm = (props: { onSuccess: () => void }) => {
  const handleSubmit = (values: any) => {
    addProject(values).then(() => {
      props.onSuccess();
    });
  };

  return <ProjectFormBase onSubmit={handleSubmit} />;
};

export default ProjectForm;
