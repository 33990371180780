import React from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";

interface IProps {
  open: boolean;
  inItem?: boolean;
}

const Loader = (props: IProps) => {
  if (props.inItem) {
    return (
      <Box display={"flex"} width={"100%"}>
        <Box margin={"auto"}>
          <CircularProgress color={"primary"} />
        </Box>
      </Box>
    );
  }

  return (
    <Backdrop style={{ color: "#fff", zIndex: 10000 }} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
