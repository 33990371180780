import React from "react";
import IArtObject from "../../types/artObject";
import { Box } from "@mui/material";

interface ISizeDisplay {
  artObject: IArtObject;
  withFrame: boolean;
}

const SizeDisplay = ({ artObject, withFrame }: ISizeDisplay) => {
  return (
    <Box>
      {withFrame ? (
        <span data-cy={"sizeWithFrame"}>
          {artObject.sizeWithFrameD} x {artObject.sizeWithFrameB}{" "}
          {artObject.sizeWithFrameH && "x "}
          {artObject.sizeWithFrameH} cm
        </span>
      ) : (
        <span data-cy={"sizeWithoutFrame"}>
          {artObject.sizeWithoutFrameD} x {artObject.sizeWithoutFrameB}{" "}
          {artObject.sizeWithoutFrameH && "x "}
          {artObject.sizeWithoutFrameH} cm
        </span>
      )}
    </Box>
  );
};
export default SizeDisplay;
