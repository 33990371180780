import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { MenuItem, Paper, Select } from "@mui/material";
import { FilterList } from "@mui/icons-material";

const useStyles = makeStyles((theme: any) => ({
  root: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 420,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-0.05px",
  },
}));

const FilterInput = (props: any) => {
  const { className, onChange, style, ...rest } = props;

  const classes = useStyles();

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <FilterList className={classes.icon} />
      <Select
        {...rest}
        className={classes.input}
        disableUnderline
        fullWidth
        onChange={onChange}
        value={props.value}
        displayEmpty
        variant={"standard"}
        data-cy={"filter"}
      >
        <MenuItem value={""}>
          <span style={{ color: "#a7a7a7" }}>Filter object groep...</span>
        </MenuItem>
        {types.map((objectType: any) => (
          <MenuItem
            key={"filter" + objectType.value}
            value={objectType.value}
            disabled={objectType.disabled}
          >
            {objectType.label}
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
};

const types = [
  {
    value: "Affiche",
    label: "Affiche",
  },
  {
    value: "Ansichtkaart",
    label: "Ansichtkaart",
  },
  {
    value: "Aquarel",
    label: "Aquarel",
  },
  {
    value: "Camera",
    label: "Camera",
  },
  {
    value: "Collage",
    label: "Collage",
  },
  {
    value: "Documentatie",
    label: "Documentatie",
  },
  {
    value: "Drukplaat",
    label: "Drukplaat",
  },
  {
    value: "Ets",
    label: "Ets",
  },
  {
    value: "Etsplaat",
    label: "Etsplaat",
  },
  {
    value: "Foto",
    label: "Foto",
  },
  {
    value: "Fotokopie",
    label: "Fotokopie",
  },
  {
    value: "Gietmal",
    label: "Gietmal",
  },
  {
    value: "Gouache",
    label: "Gouache",
  },
  {
    value: "Klok",
    label: "Klok",
  },
  {
    value: "Kostuum",
    label: "Kostuum",
  },
  {
    value: "Kunstenaarsboek",
    label: "Kunstenaarsboek",
  },
  {
    value: "Kunstenaarsmateriaal",
    label: "Kunstenaarsmateriaal",
  },
  {
    value: "Lijst",
    label: "Lijst",
  },
  {
    value: "Litho",
    label: "Litho",
  },
  {
    value: "Mixed media / Foto op linnen",
    label: "Mixed media / Foto op linnen",
  },
  {
    value: "Object",
    label: "Object",
  },
  {
    value: "Offset",
    label: "Offset",
  },
  {
    value: "Palet",
    label: "Palet",
  },
  {
    value: "Polaroid",
    label: "Polaroid",
  },
  {
    value: "Prent",
    label: "Prent",
  },
  {
    value: "Relief",
    label: "Relief",
  },
  {
    value: "Rotaprent",
    label: "Rotaprent",
  },
  {
    value: "Schetsboek",
    label: "Schetsboek",
  },
  {
    value: "Schildering",
    label: "Schildering",
  },
  {
    value: "Sculptuur",
    label: "Sculptuur",
  },
  {
    value: "Serviesgoed",
    label: "Serviesgoed",
  },
  {
    value: "Sjabloon",
    label: "Sjabloon",
  },
  {
    value: "Tekening",
    label: "Tekening",
  },
  {
    value: "Vaas",
    label: "Vaas",
  },
  {
    value: "Vlag",
    label: "Vlag",
  },
  {
    value: "Wandbord",
    label: "Wandbord",
  },
  {
    value: "Wandobject",
    label: "Wandobject",
  },
  {
    value: "Zeefdruk",
    label: "Zeefdruk",
  },
];

export default FilterInput;
