import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InputIcon from "@mui/icons-material/Input";
import { useCurrentUserStore } from "../../../../stores/useCurrentUserStore";
import { useModalStore } from "../../../../stores/useModalStore";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, onSignOut, ...rest } = props;

  const classes = useStyles();

  const { user } = useCurrentUserStore.getState();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink
          to="/app/collections"
          style={{
            color: "white",
            textDecoration: "none",
            fontWeight: "bold",
          }}
        >
          ARTCHIVE
        </RouterLink>
        <div className={classes.flexGrow} />
        {user && user.totalObjects >= user.limit ? (
          <Button
            color="inherit"
            variant={"outlined"}
            onClick={() => {
              useModalStore.setState({
                upgradeAccountModal: {
                  open: true,
                },
              });
            }}
          >
            Limiet bereikt Upgrade account
          </Button>
        ) : (
          ""
        )}
        <Box sx={{ display: { lg: "inherit", md: "none", sm: "none" } }}>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={onSignOut}
          >
            <InputIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: { lg: "none", md: "inherit", sm: "inherit" } }}>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
            data-cy={"toolbar-hamburger"}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
