import { Dispatch } from "react";
import IArtObject from "../../types/artObject";
import { apiGet, apiPost, apiPostForm } from "../../api/api";
import { loadCurrentUser } from "../../api/Users";

export const REQUEST_ART_OBJECTS = "REQUEST_ART_OBJECTS";
export const RECEIVE_ART_OBJECTS = "RECEIVE_ART_OBJECTS";
export const REQUEST_ART_OBJECT = "REQUEST_ART_OBJECT";
export const RECEIVE_ART_OBJECT = "RECEIVE_ART_OBJECT";
export const ART_OBJECT_ADDED = "ART_OBJECT_ADDED";
export const REQUEST_EXPORT_LABELS = "REQUEST_EXPORT_LABELS";
export const RECEIVE_EXPORT_LABELS = "RECEIVE_EXPORT_LABELS";
export const REDIRECT_ARTOBJECT = "REDIRECT_ARTOBJECT";

export function requestArtObjects() {
  return {
    type: REQUEST_ART_OBJECTS
  };
}

export function requestArtObject() {
  return {
    type: REQUEST_ART_OBJECT
  };
}

export function requestArtObjectLabels() {
  return {
    type: REQUEST_EXPORT_LABELS
  };
}

function receiveArtObjects(json: any) {
  return {
    type: RECEIVE_ART_OBJECTS,
    artObjects: json,
    receiveAt: Date.now()
  };
}

function receiveArtObject(json: any) {
  return {
    type: RECEIVE_ART_OBJECT,
    artObject: json
  };
}

function receiveArtObjectLabels() {
  return {
    type: RECEIVE_EXPORT_LABELS
  };
}

function artObjectAdded(projectId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchArtObjects(projectId));
    dispatch(redirectToArtObjects());
  };
}

function redirectToArtObjects() {
  return {
    type: REDIRECT_ARTOBJECT
  };
}

export function fetchArtObjects(projectId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestArtObjects());
    return apiGet(`/projects/${projectId}/art_objects`).then(json =>
      dispatch(receiveArtObjects(json))
    );
  };
}

export function fetchArtObject(projectId: number, artObjectId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestArtObject());
    return apiGet(
      `/projects/${projectId}/art_objects/${artObjectId}`
    ).then(json => dispatch(receiveArtObject(json)));
  };
}

export function addArtObject(artObject: IArtObject, imageData: any) {
  return (dispatch: Dispatch<any>) => {
    return apiPost(
      `/projects/${artObject.projectId}/art_objects`,
      artObject
    ).then((json: any) => {
      if (imageData && imageData.length > 0) {
        const formData: FormData = new FormData();
        formData.append("id", json.id);
        formData.append("imageData", dataURItoBlob(imageData));

        apiPostForm(
          `/projects/${artObject.projectId}/art_objects/${json.id}/images/form`,
          formData
        )
          .then(() => {
            dispatch(artObjectAdded(artObject.projectId));
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        dispatch(artObjectAdded(artObject.projectId));
      }
      loadCurrentUser();
    });
  };
}

function dataURItoBlob(dataURI: any) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function exportArtObjectLabels(
  projectId: number,
  exportType: string,
  search: string,
  objectType: string
) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestArtObjectLabels());
    let extraParams = `?type=${exportType}&search=${search}&objectType=${objectType}`;

    return apiGet(
      `/projects/${projectId}/art_objects/export${extraParams}`,
      false
    )
      .then((r: any) => r.blob())
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `artchive_etiket_export.pdf`); // 3. Append to html page
        document.body.appendChild(link); // 4. Force download
        link.click(); // 5. Clean up and remove the link
        //@ts-ignore
        link.parentNode.removeChild(link);
        dispatch(receiveArtObjectLabels());
      });
  };
}
