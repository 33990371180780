import React, { Component } from "react";
import { Breadcrumbs, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import SearchInput from "../utils/SearchInput";
import strings from "../../languages/strings";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

interface IProps {
  classes: any;
  onChange?: any;
  dispatch?: any;
}
interface IState {
  redirect: any;
}

class ProjectToolbar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      redirect: null
    };
  }

  handleClickNew = () => {
    this.setState({
      redirect: <Redirect to={`/app/collections/new`} />
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.redirect}
        <div className={classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              id={"collection-header"}
              style={{ color: "black" }}
              to="/app/collections"
            >
              {strings.collections}
            </Link>
          </Breadcrumbs>
          <span className={classes.spacer} />
          <Button
            id={"collection-new"}
            className={classes.newButton}
            onClick={this.handleClickNew}
          >
            {strings.collection.new}
          </Button>
        </div>
        {/* <div className={classes.row}>
          <h2>{strings.collections}</h2>
        </div> */}
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            placeholder={strings.search}
            onChange={this.props.onChange}
          />
        </div>
      </div>
    );
  }
}

const useStyles = (theme: any) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  newButton: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginLeft: theme.spacing(1)
  },
  backIcon: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(withStyles(useStyles)(ProjectToolbar));
