import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";

export interface IStrings extends LocalizedStringsMethods {
  signIn: string;
  username: string;
  password: string;
  search: string;
  export: string;
  settings: string;
  collections: string;
  collection: {
    new: string;
    artObjectsSize: string;
    fields: {
      name: string;
    };
  };
  save: string;
  loginError: string;
  explanationArtObjectExport: string;
  startTaxationNumber: string;
  endTaxationNumber: string;
  cancel: string;
  newArtObject: string;
  artObject: string;
  noArtObjectsFound: string;
  noCollectionsFound: string;
  artObjectFields: {
    id: string;
    taxationNumber: string;
    location: string;
    maker: string;
    title: string;
    year: string;
    objectType: string;
    subObjectType: string;
    material: string;
    sizeWithoutFrame: string;
    frame: string;
    sizeWithFrame: string;
    literature: string;
    comments: string;
    collection: string;
    edition: string;
  };

  dropzoneText: string;
  details: string;
}

const strings: IStrings = new LocalizedStrings({
  nl: {
    signIn: "Inloggen",
    username: "Gebruikersnaam",
    password: "Wachtwoord",
    search: "Zoek",
    export: "Exporteer",
    settings: "Instellingen",
    collections: "Collecties",
    collection: {
      new: "Nieuwe collectie",
      artObjectsSize: ":artObjectsSize Kunstwerken",

      fields: {
        name: "Collectie naam",
      },
    },
    save: "Opslaan",

    loginError: "Gebruikersnaam of wachtwoord is onjuist",
    cancel: "Annuleren",

    explanationArtObjectExport:
      "Exporteer de kunstwerken met een klik! Kies de export u wilt.",
    startTaxationNumber: "Begin inventaris nummers",
    endTaxationNumber: "Eind inventaris nummers",

    newArtObject: "Nieuw kunstwerk",
    artObject: "Kunstwerken",
    noArtObjectsFound: "Geen werken gevonden",
    noCollectionsFound: "Geen collecties gevonden",
    artObjectFields: {
      id: "Uniek nummer",
      taxationNumber: "Inventaris nummer",
      location: "Standplaats",
      maker: "Vervaardiger",
      title: "Titel",
      year: "Jaartal",
      objectType: "Objectgroep",
      subObjectType: "Objecttype",
      material: "Materiaal",
      sizeWithoutFrame: "Afmeting (zonder lijst of sokkel)",
      frame: "Lijst of sokkel",
      sizeWithFrame: "Afmeting (met lijst of sokkel)",
      literature: "Literatuur",
      comments: "Opmerkingen",
      collection: "Collectie (Eigenaar)",
      edition: "Oplage",
    },

    dropzoneText: "Sleep een foto hier naar toe of klik",
    details: "Details",
  },
});

export default strings;
