import create from "zustand";

interface IModal {
  open: boolean;
  data?: any;
}

export const useModalStore = create(() => ({
  changeCollectionModal: { open: false } as IModal,
  exportArtObjectModal: { open: false } as IModal,
  deleteArtObjectModal: { open: false } as IModal,
  upgradeAccountModal: { open: false } as IModal,
  addUserModal: { open: false } as IModal,
}));
