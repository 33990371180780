import { QueryClient } from "react-query";
import { apiBaseUrl } from "./constants";
import { useTokenStore } from "./stores/useTokenStore";

export const defaultQueryFn: any = async ({
  queryKey
}: {
  queryKey: string;
}) => {
  try {
    const { accessToken, refreshToken } = useTokenStore.getState();
    const r = await fetch(`${apiBaseUrl}${queryKey[0]}`, {
      headers: {
        "X-Access-Token": accessToken,
        "X-Refresh-Token": refreshToken,
        "Content-Type": "application/json"
      }
    });
    if (r.status !== 200) {
      throw new Error(await r.text());
    }
    const _accessToken = r.headers.get("access-token");
    const _refreshToken = r.headers.get("refresh-token");
    if (_accessToken && _refreshToken) {
      useTokenStore.getState().setTokens({
        accessToken: _accessToken,
        refreshToken: _refreshToken
      });
    }
    return r;
  } catch (e) {
    console.error("failed fetch");
    return {};
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: e => {
        if ("message" in (e as Error)) {
          console.error((e as Error).message);
        } else {
          console.error(e);
        }
      }
    },
    queries: {
      retry: false,
      staleTime: 60 * 1000 * 5,
      onError: e => {
        if ("message" in (e as Error)) {
          console.error((e as Error).message);
        }
      },
      queryFn: defaultQueryFn
    }
  }
});
