import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import SearchInput from "../utils/SearchInput";
import strings from "../../languages/strings";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import FilterInput from "../utils/FilterInput";
import { useCurrentUserStore } from "../../stores/useCurrentUserStore";
import { useModalStore } from "../../stores/useModalStore";
import MoreOptions from "../utils/buttons/MoreOptions";
import history from "../../history";
import useUrlQuery from "../utils/useUrlQuery";

interface IProps {
  classes: any;
  onChange?: any;
  changeFilter?: any;
  projectId?: number;
  dispatch?: any;
  isFetching?: boolean;
  refresh?: any;
}

const ArtObjectToolbar = (props: IProps) => {
  const { classes, projectId, onChange } = props;

  const [redirect, setRedirect] = useState<any>();
  const [search, setSearch] = useState<any>("");
  const [selected, setSelected] = useState<any>("");

  const searchParams = useUrlQuery();

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearch(searchParams.get("search"));
      onChange(searchParams.get("search"));
    }
    if (searchParams.get("filter")) {
      setSelected(searchParams.get("filter"));
      onChange(searchParams.get("filter"));
    }
  }, [searchParams]);

  const { user } = useCurrentUserStore();

  const handleExportModalClickOpen = () => {
    useModalStore.setState({
      exportArtObjectModal: {
        open: true,
        data: {
          projectId,
          search,
          objectType: selected,
        },
      },
    });
  };

  const handleClickNew = () => {
    setRedirect(
      <Redirect to={`/app/collections/${props.projectId}/artObjects/new`} />
    );
  };

  let timeout: any = null;
  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
    if (timeout != null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      onChange(event.target.value);
    }, 500);
  };

  const handleFilterChange = (event: any) => {
    setSelected(event.target.value);
    props.changeFilter({ objectType: event.target.value });
  };

  return (
    <div>
      <div className={classes.row}>
        {redirect}
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ color: "black" }} to="/app/collections">
            {strings.collections}
          </Link>
          <Link
            style={{ color: "black" }}
            to={`/app/collections/${projectId}/artObjects`}
          >
            {strings.artObject}
          </Link>
        </Breadcrumbs>
        <span className={classes.spacer} />
        <Button
          className={classes.exportButton}
          onClick={handleClickNew}
          disabled={user && user.totalObjects >= user.limit}
          data-cy={"new-artobject-button"}
        >
          {strings.newArtObject}
        </Button>
        <MoreOptions
          menuItems={[
            {
              label: "Wijzig collectie",
              onClick: () => {
                history.push(`/app/collections/${projectId}/edit`);
              },
            },
            {
              label: strings.export,
              onClick: handleExportModalClickOpen,
            },
          ]}
        />
      </div>
      <Grid container spacing={1}>
        <Grid item sm={12} md={5} lg={3}>
          <SearchInput
            className={classes.searchInput}
            placeholder={strings.search}
            onChange={handleSearchChange}
            value={search}
          />
        </Grid>
        <Grid item sm={12} md={5} lg={3}>
          <FilterInput
            className={classes.searchInput}
            onChange={handleFilterChange}
            value={selected}
          />
        </Grid>
        <Grid item sm={12} md={2} lg={3} style={{ margin: "auto 0" }}>
          <Button
            onClick={() => {
              handleFilterChange({ target: { value: "" } });
              props.onChange(null);
              setSearch("");
            }}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = (theme: any) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  newButton: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginLeft: theme.spacing(1),
  },
  backIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
});

function mapStateToProps(state: any) {
  const { artObjects } = state;
  const { isFetchingLabels } = artObjects || {
    isFetchingLabels: false,
  };

  return {
    isFetching: isFetchingLabels,
  };
}

export default connect(mapStateToProps)(
  withStyles(useStyles)(ArtObjectToolbar)
);
