import { useTokenStore } from "../stores/useTokenStore";
import { apiBaseUrl } from "../constants";

export function apiPost(endpoint: string, body: any, json: boolean = true) {
  return new Promise((resolve, reject) => {
    const accessToken = useTokenStore.getState().accessToken;
    fetch(`${apiBaseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) {
          reject(response);
        } else {
          if (json) {
            try {
              resolve(response.json());
            } catch {
              resolve(response);
            }
          } else {
            resolve(response);
          }
        }
      })
      .catch((reason) => {
        console.error(reason);
        reject();
      });
  });
}

export function apiPut(endpoint: string, body: any, json: boolean = true) {
  return new Promise((resolve, reject) => {
    const accessToken = useTokenStore.getState().accessToken;
    fetch(`${apiBaseUrl}${endpoint}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      credentials: "include",
      body: JSON.stringify(body),
    }).then((response) => {
      if (!response.ok) {
        response.json().then((reason) => reject(reason));
        return;
      }
      if (json) {
        response.json().then(resolve);
        return;
      }
      resolve(response);
    });
  });
}

export function apiPostFile(endpoint: string, file: any) {
  return new Promise((resolve, reject) => {
    const accessToken = useTokenStore.getState().accessToken;
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${apiBaseUrl}${endpoint}`, {
      method: "POST",
      headers: { Authorization: accessToken },
      credentials: "include",
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          resolve(res);
        } else {
          res.json().then((reason) => {
            reject(errorHandler(reason));
          });
        }
      })
      .catch(() => reject());
  });
}

export function apiPostForm(endpoint: string, form: any) {
  return new Promise((resolve, reject) => {
    const accessToken = useTokenStore.getState().accessToken;
    fetch(`${apiBaseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        Authorization: accessToken,
      },
      body: form,
    })
      .then((res) => {
        if (res.ok) {
          resolve(res);
        } else {
          res.json().then((reason) => {
            reject(errorHandler(reason));
          });
        }
      })
      .catch(() => reject());
  });
}

export async function apiGet(endpoint: string, json = true) {
  let accessToken = useTokenStore.getState().accessToken;

  const response = await fetch(`${apiBaseUrl}${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  });
  if (!response.ok) {
    throw new Error("Could not fetch: " + endpoint);
  }
  if (json) {
    return await response.json();
  }
  return response;
}

export async function apiDelete(endpoint: string) {
  const accessToken = useTokenStore.getState().accessToken;
  const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    method: "DELETE",
    headers: { Authorization: accessToken },
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Could not delete: " + endpoint);
  }
  return response;
}

export function apiDownload(endpoint: string, fileName: string, body: any) {
  return new Promise((resolve, reject) => {
    const accessToken = useTokenStore.getState().accessToken;
    fetch(`${apiBaseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      credentials: "include",
      body: JSON.stringify(body),
    })
      .then((r: any) => (r.ok ? r.blob() : null))
      .then((response: any) => {
        if (response === null) {
          reject();
        } else {
          downloadFile(response, fileName);
          resolve(true);
        }
      })
      .catch(reject);
  });
}

export function downloadFile(response: any, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); // 3. Append to html page
  document.body.appendChild(link); // 4. Force download
  link.click(); // 5. Clean up and remove the link
  //@ts-ignore
  link.parentNode.removeChild(link);
}

function errorHandler(reason: any): string {
  const errorCodes = {
    IMPORT_FAILED: "Something went wrong",
  };
  if (reason.code) {
    switch (reason.code) {
      case "IMPORT_FAILED":
        return errorCodes.IMPORT_FAILED.replace("#MESSAGE#", reason.message);
      default:
        break;
    }
  }

  return "Something went wrong";
}
