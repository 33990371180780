import create from "zustand";

interface IUser {
  createdAt?: any;
  updateAt?: any;
  username: string;
  role: string;
  subscription: string;
  totalObjects: number;
  limit: number;
}

export const useCurrentUserStore = create(() => ({
  user: {} as IUser
}));
