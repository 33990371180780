import React from "react";
import IProject from "../../types/project";
import CollectionCard from "./CollectionCard";
import { makeStyles } from "@mui/styles";
import { useCurrentUserStore } from "../../stores/useCurrentUserStore";

interface IProps {
  collections: any[];
  handleClick: (projectId: number) => void;
  search: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    alignItems: "flex-start" as "flex-start",
  },
}));

const ProjectListBase = (props: IProps) => {
  const classes = useStyles();
  const { search } = props;

  const { user } = useCurrentUserStore.getState();

  return (
    <div className={classes.root}>
      {props.collections
        .filter((row: IProject) => {
          return (
            search === "" ||
            row.name.toLowerCase().includes(search.toLowerCase())
          );
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((project: any, index: any) => (
          <CollectionCard
            key={index}
            collection={project}
            handleClick={() => props.handleClick(project.id)}
            showOwner={user.role === "ADMIN"}
          />
        ))}
    </div>
  );
};

export default ProjectListBase;
