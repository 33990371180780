import { apiGet } from "./api";
import { useState } from "react";
import { useQuery } from "react-query";

const fetchProject = async (projectId: any) => {
  try {
    return await apiGet(`/projects/${projectId}`);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const fetchProjects = async () => {
  try {
    return await apiGet(`/projects`);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const fetchProjectUsers = async (projectId: any) => {
  try {
    return await apiGet(`/projects/${projectId}/users`);
  } catch (e) {
    console.error(e);
    return [];
  }
};

function usePreview(projectId: any) {
  const [preview, setPreview] = useState<any>([]);

  useQuery(
    "preview" + projectId,
    () => {
      return apiGet(`/projects/${projectId}/art_objects/paged?page=0&size=3`);
    },
    {
      onSuccess: (data) => {
        setPreview(data.content);
      },
      refetchOnMount: "always",
    }
  );
  return preview;
}

export { fetchProject, fetchProjects, fetchProjectUsers, usePreview };
