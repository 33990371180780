import React, { Component } from "react";
import { Box, Grid } from "@mui/material";
import IArtObject from "../../types/artObject";
import ArtObjectFields from "./Fields";

interface IProps {
  artObject?: IArtObject;
  imageFormData?: any;
}

interface IState {
  imagePreviewUrl: any;
}

class ArtObjectOverview extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      imagePreviewUrl: ""
    };
  }

  render() {
    let imagePreview = null;
    if (this.props.imageFormData !== "") {
      imagePreview = (
        <img
          style={{ maxWidth: 500, maxHeight: 500 }}
          src={this.props.imageFormData}
          alt={""}
        />
      );
    }

    return (
      <Box marginBottom={"1rem"}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.props.artObject && (
              <ArtObjectFields artObject={this.props.artObject} />
            )}
          </Grid>
          <Grid item xs={6} data-cy={"image-preview"}>
            {imagePreview}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default ArtObjectOverview;
