import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography
} from "@mui/material";
import strings from "../../languages/strings";
import { makeStyles } from "@mui/styles";
import history from "../../history";
import { usePreview } from "../../api/Projects";

const useStyles = makeStyles((theme: any) => ({
  projectWrapper: {
    width: "50%",
    marginTop: theme.spacing(2)
  },
  projectCard: {
    marginRight: theme.spacing(2)
  }
}));

interface IProps {
  collection: any;
  handleClick: any;
  showOwner: boolean;
}

function CollectionCard(props: IProps) {
  const { collection } = props;
  const classes = useStyles();
  const preview: any = []; //usePreview(collection.id);

  return (
    <div className={classes.projectWrapper} data-cy={"collection-card"}>
      <Card key={collection.id} className={classes.projectCard}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                component="h2"
                data-cy={"collection-card-name"}
              >
                <span data-cy={"collection-card-name"}>{collection.name}</span>
              </Typography>
              <Grid container>
                {preview.size === 3 &&
                  preview.map((artObject: any) => (
                    <Grid item xs={4}>
                      <img src={artObject.imageUrl} alt={""} width={"100%"} />
                    </Grid>
                  ))}
              </Grid>
              <Typography color="textSecondary">
                {collection.artObjectSize > 0
                  ? strings.collection.artObjectsSize.replace(
                      ":artObjectsSize",
                      collection.artObjectSize
                    )
                  : "Bevat nog geen kunstwerken"}
              </Typography>
              {props.showOwner && (
                <Typography color="textSecondary">
                  {collection.owner}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            className={"goto-collection-button"}
            size="small"
            onClick={() => {
              props.handleClick(collection.id);
              history.push(`/app/collections/${collection.id}/artObjects`);
            }}
            data-cy={"goto-collection-button"}
          >
            Bekijk kunstwerken
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

export default CollectionCard;
