import React, { useState } from "react";
import { Box, Breadcrumbs, Card, CardContent } from "@mui/material";
import strings from "../../languages/strings";
import { Link } from "react-router-dom";
import { Wizard } from "../../components/artObjects";
import { useMutation, useQuery } from "react-query";
import { makeStyles } from "@mui/styles";
import { apiDelete, apiGet, apiPostForm, apiPut } from "../../api/api";
import Loader from "../../components/utils/Loader";
import { dataURItoBlob } from "../../components/utils/imageUtils";
import Header from "../../components/collections/Header";

const EditArtObject = (props: any) => {
  const classes = useStyles();
  const {
    match: { params },
  } = props;

  const [loadedArtObject, setLoadedArtObject] = useState<any>({});

  const { isLoading } = useQuery(
    "artObject" + params.artObjectId,
    async () => {
      return apiGet(
        `/projects/${params.projectId}/art_objects/${params.artObjectId}`
      );
    },
    {
      onSuccess: (data) => {
        setLoadedArtObject(data);
      },
      refetchOnMount: "always",
    }
  );

  const save = useMutation(
    async (values: any) => {
      const { artObject, imageData } = values;
      artObject.projectId = params.projectId;

      const saveResponse: any = await apiPut(
        `/projects/${params.projectId}/art_objects/${artObject.id}`,
        artObject
      );

      // ImageData is -1 when not updated, this is used so we can load the thumbnail file and not overwrite the HQ file.
      // Or when the image is already an link to the image
      if (imageData === -1) {
        return;
      }

      if (imageData && imageData.length > 0) {
        const formData: FormData = new FormData();
        formData.append("id", artObject.id);
        formData.append("imageData", dataURItoBlob(imageData));

        await apiPostForm(
          `/projects/${artObject.projectId}/art_objects/${saveResponse.id}/images/form`,
          formData
        );
      } else {
        await apiDelete(
          `/projects/${artObject.projectId}/art_objects/${saveResponse.id}/images`
        );
      }

      return {};
    },
    {
      onSuccess: () => {
        props.history.push(
          `/app/collections/${params.projectId}/artObjects/${params.artObjectId}`
        );
      },
    }
  );

  return (
    <Box className={classes.root}>
      <Header projectId={params.projectId} />
      <Loader open={save.isLoading} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: "black" }} to="/app/collections">
          {strings.collections}
        </Link>
        <Link
          style={{ color: "black" }}
          to={`/app/collections/${params.projectId}/artObjects/${params.artObjectId}`}
        >
          {strings.artObject}
        </Link>
      </Breadcrumbs>

      <Card className={classes.content}>
        <Loader open={isLoading} />
        <CardContent>
          <Wizard
            initialValues={loadedArtObject}
            onSubmit={(artObject: any, imageData: any) => {
              save.mutate({ artObject, imageData });
            }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
}));

export default EditArtObject;
