import React, { useEffect, useState } from "react";
import { Toolbar as ArtObjectToolbar } from "../components/artObjects";
import { makeStyles } from "@mui/styles";
import Header from "../components/collections/Header";
import Table from "../components/artObjects/ArtObjectsTable";
import useUrlQuery from "../components/utils/useUrlQuery";
interface IProps {
  match?: any;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ArtObjectPage = (props: IProps) => {
  const {
    match: { params },
  } = props;

  const searchParams = useUrlQuery();
  const classes = useStyles();

  const [search, setSearch] = useState<any>("");
  const [filter, setFilter] = useState<any>(false);
  const [page, setPage] = useState<any>(0);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(Number.parseInt(searchParams.get("page") as string));
    }
  }, [searchParams]);

  const handleSearchChange = (value: any) => {
    setSearch(value);
    searchParams.set("search", value);
  };

  const handleFilterChange = (value: any) => {
    setFilter(value);
    searchParams.set("filter", filter);
  };

  const refreshTable = () => {};

  return (
    <div className={classes.root}>
      <Header id="collection-name-header" projectId={params.projectId} />
      <ArtObjectToolbar
        onChange={handleSearchChange}
        changeFilter={handleFilterChange}
        projectId={params.projectId}
        refresh={refreshTable}
      />
      <div className={classes.content}>
        <Table
          projectId={params.projectId}
          search={search}
          filter={filter}
          page={page}
        />
      </div>
    </div>
  );
};

export default ArtObjectPage;
