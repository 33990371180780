import React from "react";
import { Button, DialogContentText } from "@mui/material";
import ModalDialog from "../components/utils/ModalDialog";
import { useModalStore } from "../stores/useModalStore";

function UpgradeAccountModal() {
  const {
    upgradeAccountModal: { open }
  } = useModalStore();

  const close = () => {
    useModalStore.setState({
      upgradeAccountModal: { open: false, data: {} }
    });
  };

  return (
    <ModalDialog
      open={open}
      onClose={close}
      title={"Account upgraden"}
      actions={
        <>
          <div></div>
          <Button onClick={close} color={"primary"}>
            Ok
          </Button>
        </>
      }
    >
      <DialogContentText>
        U heeft het maximaal in te voeren werken in uw abonnement bereikt. U
        kunt uw limiet verhogen door een bericht te sturen aan: <br />
        info@miriamwindhausen.nl
      </DialogContentText>
    </ModalDialog>
  );
}

export default UpgradeAccountModal;
