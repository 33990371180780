import { Button, Link } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React from "react";

const GoBack = (props: { label: string; to: string }) => {
  return (
        <Button href={props.to} data-cy={"go-back"}>
            <ArrowBack style={{ marginRight: "10px" }} />
            {props.label}
        </Button>
  );
};

export default GoBack;
