import React, { ReactElement } from "react";
import { Form } from "react-final-form";
import { Box, Button, Divider, Step, StepLabel, Stepper } from "@mui/material";
import arrayMutators from "final-form-arrays";

interface IProps {
  children: any;
  onSubmit: any;
  steps: any;
  onPageSwitch: any;
  initialValues: any;
}

export default class Wizard extends React.Component<IProps, any> {
  // Page passes the values from to its child as props.
  static Page = ({ children, values }: any) => {
    return React.cloneElement(children, { values });
  };

  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
    };
  }

  next = (values: any) => {
    this.setState((state: any) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));
    this.props.onPageSwitch(values);
  };

  previous = () => {
    this.setState((state: any) => ({
      page: Math.max(state.page - 1, 0),
    }));
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values: any) => {
    const activePage: any = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values: any) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      console.log("Wizard: ", values);
      return onSubmit(values);
    } else {
      this.next(values);
    }
  };

  render() {
    const { children, initialValues } = this.props;
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page] as ReactElement;
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Form
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <>
            <Box marginBottom={"1rem"}>
              <Stepper activeStep={this.state.page}>
                {this.props.steps.map((label: any) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            <Box marginBottom={"2rem"}>
              <Divider />
            </Box>
            <Box>
              <form onSubmit={handleSubmit}>
                {React.cloneElement(activePage, { values })}
                <Box>
                  <Divider />
                </Box>
                <Box
                  marginTop={"1rem"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {page > 0 ? (
                    <Button
                      onClick={this.previous}
                      style={{ paddingRight: "2rem" }}
                    >
                      Vorige
                    </Button>
                  ) : (
                    <Box></Box>
                  )}
                  {!isLastPage && (
                    <Button
                      color="primary"
                      variant={"contained"}
                      type={"submit"}
                      data-cy={"wizard-next"}
                    >
                      Volgende
                    </Button>
                  )}
                  {isLastPage && (
                    <Button
                      type="submit"
                      color={"primary"}
                      variant={"contained"}
                      data-cy={"wizard-save"}
                      disabled={submitting}
                    >
                      Opslaan
                    </Button>
                  )}
                </Box>
              </form>
            </Box>
          </>
        )}
      </Form>
    );
  }
}
