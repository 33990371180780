import React, { useState } from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import { ThemeProvider } from "@mui/material";
import theme from "./components/theme";
import Routes from "./Routes";
import strings from "./languages/strings";
import ReactGA from "react-ga";
import { useQuery } from "react-query";
import { useTokenStore } from "./stores/useTokenStore";
import LoginPage from "./pages/LoginPage";
import { apiPost } from "./api/api";
import { Route, Router, Switch } from "react-router-dom";
import Modals from "./modals/Modals";
import history from "./history";
import HomeScreen from "./pages/HomeScreen";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { NavigationContainer } from "@react-navigation/native";

const store = configureStore({});

const trackingId = "UA-170126555-1";

ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  strings.setLanguage("nl");
  const tokenStore = useTokenStore.getState();
  const [auth, setAuth] = useState<any>(true);
  useTokenStore.subscribe(({ accessToken }) => {
    if (accessToken) setAuth(true);
  });

  ReactGA.set({
    userId: 1,
  });

  useQuery<any>(
    "auth",
    () => {
      if (!tokenStore.refreshToken || tokenStore.refreshToken === "") {
        setAuth(false);
        return;
      }

      return apiPost(`/users/refresh_token`, {
        refreshToken: tokenStore.refreshToken,
      });
    },
    {
      onSuccess: (data) => {
        if (data && data.accessToken) {
          useTokenStore.getState().setTokens({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          });
          setAuth(true);
        }
      },
      onError: (err) => {
        console.error(err);
        useTokenStore
          .getState()
          .setTokens({ accessToken: "", refreshToken: "" });
        setAuth(false);
      },
    }
  );

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {auth ? (
          <>
            <Routes />
            <Modals />
          </>
        ) : (
          <Router history={history}>
            <Switch>
              <Route
                path="/app/resetpassword/:email/:token"
                component={ResetPasswordPage}
              />
              <Route path={"/app"} component={LoginPage} />
              <Route key="0" path="/" component={HomeScreen} />
            </Switch>
          </Router>
        )}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
