import React, { useState } from "react";
import {
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import ModalDialog from "../components/utils/ModalDialog";
import { apiPut } from "../api/api";
import { useModalStore } from "../stores/useModalStore";
import { connect } from "react-redux";
import history from "../history";
import { fetchArtObjects } from "../redux/actions/artObjectActions";
import { useQuery } from "react-query";
import { fetchProjects } from "../api/Projects";

/**
 * Modal to move an ArtObject to a different collection.
 * Takes two different attributes in the data prop:
 *  - artObjectId: The id of the artobject that has to be moved
 *  - projectId: The project ID that this model needs to redirect to after moving the artObject.
 */
const ChangeCollectionModal = (props: any) => {
  const {
    changeCollectionModal: { open, data }
  } = useModalStore();

  const [projectList, setProjectList] = useState([]);
  const [selected, setSelected] = useState<string>("");
  const [error, setError] = useState<string>("");

  const { refetch } = useQuery("projects", fetchProjects, {
    onSuccess: projects => {
      setProjectList(projects);
    },
    onError: () => {}
  });

  useModalStore.subscribe(({ changeCollectionModal }) => {
    if (changeCollectionModal.open) {
      refetch().then(d => console.log("data", d));
    }
  });

  const handleSelectedProjectChange = (event: SelectChangeEvent) => {
    setError("");
    setSelected(event.target.value as string);
  };

  const close = () => {
    setSelected("");
    useModalStore.setState({
      changeCollectionModal: { open: false, data: {} }
    });
  };

  const handleConfirm = () => {
    setError("");
    if (selected === "") {
      setError("Selecteer een collectie");
      return;
    }
    apiPut(
      `/projects/${selected}/art_objects/${data.artObjectId}/changeProject`,
      {}
    ).then(() => {
      if (data.projectId) {
        history.push(`/app/collections/${data.projectId}/artObjects`);
        props.dispatch(fetchArtObjects(Number.parseInt(data.projectId)));
      }
      close();
    });
  };

  return (
    <ModalDialog
      open={open}
      onClose={close}
      title={"Verplaats naar collectie"}
      actions={
        <>
          <Button onClick={close}>Annuleer</Button>
          <Button onClick={handleConfirm} color={"primary"}>
            Verplaats
          </Button>
        </>
      }
    >
      <DialogContentText>
        Selecteer een collectie om het kunstwerk te verplaatsen.
      </DialogContentText>
      <Typography color={"error"}>{error}</Typography>
      <FormControl variant={"outlined"} fullWidth>
        <InputLabel id={"collectionLabel"}>Selecteer collectie</InputLabel>
        <Select
          labelId={"collectionLabel"}
          label={"Selecteer collectie"}
          value={selected}
          fullWidth
          error={error !== ""}
          onChange={handleSelectedProjectChange}
          variant={"outlined"}
        >
          {projectList
            .filter((a: any) => a.name != null)
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .map((p: any) => (
              <MenuItem key={"projects" + p.id} value={p.id}>
                {p.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </ModalDialog>
  );
};

export default connect(() => ({}))(ChangeCollectionModal);
