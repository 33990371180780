import React from "react";

const DividerText = ({ children }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center", opacity: 0.6 }}>
      {/*<div style={{ borderBottom: "1px solid #eeeeee", width: "15px" }} />*/}
      <span style={{ padding: "0 10px 0 10px", fontSize: "14px" }}>
        {children}
      </span>
      <div style={{ borderBottom: "1px solid #eeeeee", width: "100%" }} />
    </div>
  );
};

export default DividerText;
