import rootReducer from "./reducers/reducers";
import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState: any) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunkMiddleware)
    // applyMiddleware(thunkMiddleware, loggerMiddleware)
  );
}
