import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCurrentUserStore } from "../../stores/useCurrentUserStore";
import { useMutation, useQuery } from "react-query";
import { fetchProject, fetchProjectUsers } from "../../api/Projects";
import { fetchUsers } from "../../api/Users";
import { apiPost } from "../../api/api";
import SearchIcon from "@mui/icons-material/Search";
import {
  Delete,
  MoreHoriz,
  Remove,
  SwitchAccessShortcut,
} from "@mui/icons-material";

const EditUsers = ({ projectId }: { projectId: number }) => {
  const { user } = useCurrentUserStore.getState();

  if (!user || (user.role !== "ADMIN" && user.role !== "MANAGER")) {
    return <Box></Box>;
  }

  const [projectsUsers, setProjectsUsers] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [project, setProject] = useState<any>();
  const [newUser, setNewUser] = useState<any>("");
  const [searchText, setSearchText] = useState("");

  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => users.filter((user: any) => containsText(user.username, searchText)),
    [searchText]
  );

  const { refetch } = useQuery(
    ["projectsUsers", projectId],
    () => fetchProjectUsers(projectId),
    {
      onSuccess: (data) => {
        setProjectsUsers(data);
      },
      refetchOnMount: "always",
    }
  );

  const projectQuery = useQuery(
    ["project", projectId],
    () => fetchProject(projectId),
    {
      onSuccess: (data) => {
        setProject(data);
      },
      refetchOnMount: "always",
    }
  );

  const usersQuery = useQuery(["users"], () => fetchUsers(), {
    onSuccess: (data) => {
      setUsers(
        data.sort((a: any, b: any) => a.username.localeCompare(b.username))
      );
    },
    refetchOnMount: "always",
  });

  const handleAddNewUser = useMutation(
    async () => {
      await apiPost(`/projects/${projectId}/users`, {
        userId: newUser,
      });
    },
    {
      onSuccess: () => {
        setNewUser("");
        refetch();
      },
    }
  );

  const revokeAccess = useMutation(
    async (userId) => {
      await apiPost(`/projects/${projectId}/revokeAccess`, {
        userId,
      });
    },
    {
      onSuccess: () => {
        refetch();
        projectQuery.refetch();
      },
    }
  );

  const makeOwner = useMutation(
    async (userId) => {
      await apiPost(`/projects/${projectId}/makeOwner`, {
        userId,
      });
    },
    {
      onSuccess: () => {
        refetch();
        projectQuery.refetch();
      },
    }
  );

  return (
    <Box>
      <Box pt={1} display={"flex"}>
        <Box width={"100%"}>
          <Typography mb={1}>Nieuwe gebruiker:</Typography>
          <Select
            MenuProps={{ autoFocus: false }}
            value={newUser}
            fullWidth
            onChange={(e) => setNewUser(e.target.value)}
            onClose={() => setSearchText("")}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e: any) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions.map((user: any, i: any) => (
              <MenuItem key={i} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box ml={3} mt={"auto"}>
          <Button
            id={"collection-submit"}
            onClick={() => handleAddNewUser.mutate()}
            variant="contained"
            color={"primary"}
          >
            Voeg toe
          </Button>
        </Box>
      </Box>
      <Box mt={1}>
        {projectsUsers &&
          projectsUsers
            .sort((a: any, b: any) => a.username.localeCompare(b.username))
            .map((user: any) => (
              <Box key={user.id}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  my={2}
                >
                  <Typography>{user.username}</Typography>
                  <Typography>
                    {project && project.ownerId === user.id ? (
                      "Hoofd Gebruiker"
                    ) : (
                      <Box>
                        <Tooltip title={"Maak hoofd gebruiker"}>
                          <IconButton
                            color="primary"
                            size="medium"
                            onClick={() => makeOwner.mutate(user.id)}
                          >
                            <SwitchAccessShortcut />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Verwijder van collectie"}>
                          <IconButton
                            color="primary"
                            size="medium"
                            onClick={() => revokeAccess.mutate(user.id)}
                            style={{ marginLeft: 6 }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default EditUsers;
