/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { List, ListItem, Button, colors, Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const renderListItem = (page, className, classes) => {
  return (
    <ListItem
      sx={{
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
      }}
      disableGutters
      key={page.title}
    >
      <Button
        id={page.id}
        activeClassName={classes.active}
        sx={{
          color: colors.blueGrey[800],
          justifyContent: "flex-start",
          textTransform: "none",
          letterSpacing: 0,
          width: "100%",
        }}
        component={CustomRouterLink}
        to={page.href}
      >
        <div className={classes.icon}>{page.icon}</div>
        {page.title}
      </Button>
    </ListItem>
  );
};

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const topItems = pages.filter((page) => page.bottom !== true);
  const bottomItems = pages.filter((page) => page.bottom === true);

  return (
    <div className={clsx(classes.root, className)}>
      <List {...rest}>
        {topItems.map((page) => {
          return renderListItem(page, className, classes);
        })}
      </List>

      <List {...rest}>
        <Divider className={classes.divider} />
        {bottomItems.map((page) => {
          return renderListItem(page, className, classes);
        })}
      </List>
    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
