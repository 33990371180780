import React from "react";
import { Button } from "@mui/material";
import { Form } from "react-final-form";
import strings from "../../languages/strings";
import { TextField } from "mui-rff";

const LoginFormBase = (props: any) => {
  const { onSubmit } = props;

  const basicProps = {
    variant: "standard" as "standard",
    sx: { marginTop: 2 },
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            id={"email"}
            label={strings.username}
            name="username"
            {...basicProps}
          />
          <TextField
            id={"password"}
            label={strings.password}
            name="password"
            type={"password"}
            {...basicProps}
          />
          <Button
            id={"loginButton"}
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ marginTop: 2 }}
          >
            {strings.signIn}
          </Button>
        </form>
      )}
    />
  );
};

export default LoginFormBase;
