import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import LoginForm from "../components/authentication/LoginForm";
import Loader from "../components/utils/Loader";
import { makeStyles } from "@mui/styles";

interface IProps {
  isFetching: boolean;
  error?: string;
}

const LoginPage = (props: IProps) => {
  const classes = useStyles();

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const loginForm = <LoginForm />;

  return (
    <div className={classes.root}>
      <Loader open={props.isFetching} />
      {smallScreen && (
        <Box style={{ padding: "2rem" }}>
          <h1>ARTCHIVE</h1>
          <Typography color="error" variant="body1">
            {props.error}
          </Typography>
          {loginForm}
          <Typography className={classes.name} variant="body1">
            Foto: nalatenschap Hans Bosman (1958-2012)
          </Typography>
        </Box>
      )}

      {mediumScreen && !smallScreen && (
        <Grid container className={classes.grid}>
          <Box className={classes.quoteContainerSmall}>
            <div className={classes.quote}></div>
          </Box>

          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
            justifyContent={"center"}
            style={{ marginTop: "20%" }}
          >
            <Box className={classes.content}>
              <Box>
                <Paper style={{ padding: "2rem" }}>
                  <h1>ARTCHIVE</h1>
                  <Typography color="error" variant="body1">
                    {props.error}
                  </Typography>
                  {loginForm}
                  <Typography style={{ color: "grey", marginTop: 12 }}>
                    Foto: nalatenschap Hans Bosman (1958-2012)
                  </Typography>
                </Paper>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {!mediumScreen && !smallScreen && (
        <Grid container height={"100%"}>
          <Grid item md={5} lg={5}>
            <div className={classes.quote}></div>

            <Typography className={classes.namePosition} variant="body1">
              Foto: nalatenschap Hans Bosman (1958-2012)
            </Typography>
          </Grid>
          <Grid className={classes.content} item lg={7} md={5} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <h1>ARTCHIVE</h1>
              </div>
              <div className={classes.contentBody}>
                <div className={classes.form}>
                  <Typography color="error" variant="body1">
                    {props.error}
                  </Typography>
                  {loginForm}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  const { auth } = state;
  const { isFetching, error } = auth || {
    isFetching: false,
    error: null,
  };

  return {
    isFetching,
    error,
  };
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
    position: "absolute",
  },
  quoteContainerSmall: {
    position: "fixed",
    height: "100%",
    width: "100%",
    left: "0px",
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    backgroundImage: "url(/images/loginimage.jpg)",
    opacity: 0.9,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  name: {
    color: theme.palette.grey,
  },
  namePosition: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey,
    position: "absolute" as "absolute",
    bottom: 0,
    left: "42%",
  },
  bio: {
    color: theme.palette.black,
  },
  contentContainer: {},
  content: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: 100,
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

export default connect(mapStateToProps)(LoginPage);
