const ObjectTypes = {
  select: [
    {
      value: "",
      label: "Kies een type...",
      disabled: true,
    },
    { label: "Blinddruk", value: "Blinddruk", category: "Grafiek" },
    { label: "Ets", value: "Ets", category: "Grafiek" },
    { label: "Gravure", value: "Gravure", category: "Grafiek" },
    { label: "Houtsnede", value: "Houtsnede", category: "Grafiek" },
    { label: "Inkjetdruk", value: "Inkjetdruk", category: "Grafiek" },
    { label: "Linoleumsnede", value: "Linoleumsnede", category: "Grafiek" },
    { label: "Litho", value: "Litho", category: "Grafiek" },
    { label: "Mezzotint", value: "Mezzotint", category: "Grafiek" },
    {
      label: "Monotype / frottage",
      value: "Monotype / frottage",
      category: "Grafiek",
    },
    { label: "Rotaprent", value: "Rotaprent", category: "Grafiek" },
    { label: "Zeefdruk", value: "Zeefdruk", category: "Grafiek" },
    {
      label: "Achterglasschildering",
      value: "Achterglasschildering",
      category: "Schildering",
    },
    { label: "Airbrush", value: "Airbrush", category: "Schildering" },
    { label: "Aquarel", value: "Aquarel", category: "Schildering" },
    { label: "Email", value: "Email", category: "Schildering" },
    {
      label: "Encaustiek (was)",
      value: "Encaustiek (was)",
      category: "Schildering",
    },
    {
      label: "Gebrandschilderd glas",
      value: "Gebrandschilderd glas",
      category: "Schildering",
    },
    { label: "Gouache", value: "Gouache", category: "Schildering" },
    { label: "Graffitti", value: "Graffitti", category: "Schildering" },
    { label: "Schilderij", value: "Schilderij", category: "Schildering" },
    { label: "Papiercollage", value: "Papiercollage", category: "Collage" },
    { label: "Fotocollage", value: "Fotocollage", category: "Collage" },
    { label: "Sgraffito", value: "Sgraffito", category: "Wandkunst" },
    {
      label: "Muurschildering",
      value: "Muurschildering",
      category: "Wandkunst",
    },
    { label: "Fresco", value: "Fresco", category: "Wandkunst" },
    { label: "Mozaïek", value: "Mozaïek", category: "Wandkunst" },
    { label: "Daguerrotype", value: "Daguerrotype", category: "Fotografie" },
    {
      label: "Dye transferdruk",
      value: "Dye transferdruk",
      category: "Fotografie",
    },
    {
      label: "Inkjet kleursublimatiedruk",
      value: "Inkjet kleursublimatiedruk",
      category: "Fotografie",
    },
    { label: "Inkjetdruk", value: "Inkjetdruk", category: "Fotografie" },
    {
      label: "Kleurendia (cromogene dia)",
      value: "Kleurendia (cromogene dia)",
      category: "Fotografie",
    },
    {
      label: "Kleurenfoto (chromogeendruk)",
      value: "Kleurenfoto (chromogeendruk)",
      category: "Fotografie",
    },
    {
      label: "Platina/palladium druk",
      value: "Platina/palladium druk",
      category: "Fotografie",
    },
    {
      label: "Polaroid kleur (kleurdiffusie transferdruk)",
      value: "Polaroid kleur (kleurdiffusie transferdruk)",
      category: "Fotografie",
    },
    {
      label: "Polaroid zwart-wit (zilverdiffusie transferdruk)",
      value: "Polaroid zwart-wit (zilverdiffusie transferdruk)",
      category: "Fotografie",
    },
    {
      label: "Zilverkleurbleekdruk",
      value: "Zilverkleurbleekdruk",
      category: "Fotografie",
    },
    { label: "Zwart-wit dia", value: "Zwart-wit dia", category: "Fotografie" },
    {
      label: "Zwart-wit foto (ontwikkelgelatinezilverdruk)",
      value: "Zwart-wit foto (ontwikkelgelatinezilverdruk)",
      category: "Fotografie",
    },
    { label: "Assemblage", value: "Assemblage", category: "Ruimtelijkwerk" },
    {
      label: "Bozzetto (schets in klei of was)",
      value: "Bozzetto (schets in klei of was)",
      category: "Ruimtelijkwerk",
    },
    { label: "Installatie", value: "Installatie", category: "Ruimtelijkwerk" },
    { label: "Land Art", value: "Land Art", category: "Ruimtelijkwerk" },
    { label: "Relief", value: "Relief", category: "Ruimtelijkwerk" },
    { label: "Sculptuur", value: "Sculptuur", category: "Ruimtelijkwerk" },
    { label: "Film", value: "Film", category: "Timebasedwork" },
    { label: "Performance", value: "Performance", category: "Timebasedwork" },
    { label: "Video", value: "Video", category: "Timebasedwork" },
    { label: "Aardewerk", value: "Aardewerk", category: "Keramiek" },
    { label: "Bord", value: "Bord", category: "Keramiek" },
    { label: "Porselein", value: "Porselein", category: "Keramiek" },
    { label: "Schaal", value: "Schaal", category: "Keramiek" },
    { label: "Serviesgoed", value: "Serviesgoed", category: "Keramiek" },
    { label: "Steengoed", value: "Steengoed", category: "Keramiek" },
    { label: "Vaas", value: "Vaas", category: "Keramiek" },
    { label: "Camera", value: "Camera", category: "Object" },
    { label: "Drukplaat", value: "Drukplaat", category: "Object" },
    { label: "Etsplaat", value: "Etsplaat", category: "Object" },
    { label: "Gietmal", value: "Gietmal", category: "Object" },
    { label: "Klok", value: "Klok", category: "Object" },
    { label: "Kostuum", value: "Kostuum", category: "Object" },
    { label: "Lijst", value: "Lijst", category: "Object" },
    { label: "Palet", value: "Palet", category: "Object" },
    { label: "Sieraad", value: "Sieraad", category: "Object" },
    { label: "Sjabloon", value: "Sjabloon", category: "Object" },
    { label: "Vlag", value: "Vlag", category: "Object" },
    {
      label: "Kunstenaarsmateriaal",
      value: "Kunstenaarsmateriaal",
      category: "Materiaal",
    },
    { label: "Kunstenaarsboek", value: "Kunstenaarsboek", category: "Boek" },
    { label: "Schetsboek", value: "Schetsboek", category: "Boek" },
    { label: "Email (?)", value: "Email (?)", category: "Glas" },
    { label: "Glas-in-lood", value: "Glas-in-lood", category: "Glas" },
    {
      label: "Wandtapijt (gobelin)",
      value: "Wandtapijt (gobelin)",
      category: "Textiel",
    },
    { label: "Vlag", value: "Vlag", category: "Textiel" },
    { label: "Pasteltekening", value: "Pasteltekening", category: "Tekening" },
    { label: "Tekening", value: "Tekening", category: "Tekening" },
    { label: "Poster", value: "Poster", category: "Tekening" },
    { label: "Poster", value: "Poster", category: "Informatiedrager" },
    {
      label: "Ansichtkaart",
      value: "Ansichtkaart",
      category: "Informatiedrager",
    },
    { label: "Drukwerk", value: "Drukwerk", category: "Informatiedrager" },
    {
      label: "Documentatie",
      value: "Documentatie",
      category: "Informatiedrager",
    },
    { label: "Fotokopie", value: "Fotokopie", category: "Informatiedrager" },
  ],

  subSelect: (category: string) => {
    console.log("category,", category);
    if (category !== undefined) {
      const object = ObjectTypes.select.filter(
        (item) => item.label == category
      );
      const flatObject = object.flat();
      if (flatObject.length == 0) {
        console.log("Just nothing found here");
        return subTypes["NoType"];
      }
      const categoryFound = flatObject.at(0)!.category;
      return subTypes[categoryFound!];
    } else {
      return subTypes["NoType"];
    }
  },
};

const subTypes: any = {
  NoType: [
    {
      label: "Kies eerst een objecttype..",
      value: "Kies eerst een objecttype",
    },
  ],
  Grafiek: [
    {
      label: "Grafiek (Prenten)",
      value: "Grafiek",
    },
  ],
  Schildering: [
    {
      label: "Schildering",
      value: "Schildering",
    },
  ],
  Collage: [
    {
      label: "Collage",
      value: "Collage",
    },
  ],
  GemengdeTechniek: [
    {
      label: "Gemengde techniek (2D)",
      value: "Gemengde techniek",
    },
  ],
  Wandkunst: [
    {
      label: "Wandkunst",
      value: "Wandkunst",
    },
  ],
  Fotografie: [
    {
      label: "Fotografie",
      value: "Fotografie",
    },
  ],
  Ruimtelijkwerk: [
    {
      label: "Ruimtelijk werk",
      value: "Ruimtelijk werk",
    },
  ],
  Timebasedwork: [
    {
      label: "Time based work",
      value: "Time based work",
    },
  ],
  Keramiek: [
    {
      label: "Keramiek",
      value: "Keramiek",
    },
  ],
  Object: [
    {
      label: "Object",
      value: "Object",
    },
  ],
  Materiaal: [
    {
      label: "Materiaal",
      value: "Materiaal",
    },
  ],
  Boek: [
    {
      label: "Boek",
      value: "Boek",
    },
  ],
  Glas: [
    {
      label: "Glas",
      value: "Glas",
    },
  ],
  Textiel: [
    {
      label: "Textiel",
      value: "Textiel",
    },
  ],
  Tekening: [
    {
      label: "Tekening",
      value: "Tekening",
    },
  ],
  Informatiedrager: [
    {
      label: "Informatiedrager",
      value: "Informatiedrager",
    },
  ],
};

//
//   return [
//     {
//       value: "",
//       label: "Kies een type...",
//       disabled: true,
//     },
//     {
//       value: "Affiche",
//       label: "Affiche",
//     },
//     {
//       value: "Ansichtkaart",
//       label: "Ansichtkaart",
//     },
//     {
//       value: "Aquarel",
//       label: "Aquarel",
//     },
//     {
//       value: "Camera",
//       label: "Camera",
//     },
//     {
//       value: "Collage",
//       label: "Collage",
//     },
//     {
//       value: "Documentatie",
//       label: "Documentatie",
//     },
//     {
//       value: "Drukplaat",
//       label: "Drukplaat",
//     },
//     {
//       value: "Ets",
//       label: "Ets",
//     },
//     {
//       value: "Etsplaat",
//       label: "Etsplaat",
//     },
//     {
//       value: "Foto",
//       label: "Foto",
//     },
//     {
//       value: "Fotokopie",
//       label: "Fotokopie",
//     },
//     {
//       value: "Gietmal",
//       label: "Gietmal",
//     },
//     {
//       value: "Gouache",
//       label: "Gouache",
//     },
//     {
//       value: "Klok",
//       label: "Klok",
//     },
//     {
//       value: "Kostuum",
//       label: "Kostuum",
//     },
//     {
//       value: "Kunstenaarsboek",
//       label: "Kunstenaarsboek",
//     },
//     {
//       value: "Kunstenaarsmateriaal",
//       label: "Kunstenaarsmateriaal",
//     },
//     {
//       value: "Lijst",
//       label: "Lijst",
//     },
//     {
//       value: "Litho",
//       label: "Litho",
//     },
//     {
//       value: "Mixed media / Foto op linnen",
//       label: "Mixed media / Foto op linnen",
//     },
//     {
//       value: "Object",
//       label: "Object",
//     },
//     {
//       value: "Offset",
//       label: "Offset",
//     },
//     {
//       value: "Palet",
//       label: "Palet",
//     },
//     {
//       value: "Polaroid",
//       label: "Polaroid",
//     },
//     {
//       value: "Prent",
//       label: "Prent",
//     },
//     {
//       value: "Relief",
//       label: "Relief",
//     },
//     {
//       value: "Rotaprent",
//       label: "Rotaprent",
//     },
//     {
//       value: "Schetsboek",
//       label: "Schetsboek",
//     },
//     {
//       value: "Schilderij",
//       label: "Schilderij",
//     },
//     {
//       value: "Sculptuur",
//       label: "Sculptuur",
//     },
//     {
//       value: "Serviesgoed",
//       label: "Serviesgoed",
//     },
//     {
//       value: "Sieraad",
//       label: "Sieraad",
//     },
//     {
//       value: "Sjabloon",
//       label: "Sjabloon",
//     },
//     {
//       value: "Tekening",
//       label: "Tekening",
//     },
//     {
//       value: "Vaas",
//       label: "Vaas",
//     },
//     {
//       value: "Vlag",
//       label: "Vlag",
//     },
//     {
//       value: "Wandbord",
//       label: "Wandbord",
//     },
//     {
//       value: "Wandobject",
//       label: "Wandobject",
//     },
//     {
//       value: "Zeefdruk",
//       label: "Zeefdruk",
//     },
//   ];
// };

export { ObjectTypes, subTypes };
