import React, { Dispatch, useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, Grid, Paper } from "@mui/material";
import IArtObject from "../types/artObject";
import { connect } from "react-redux";
import { Fields as ArtObjectFields } from "../components/artObjects";
import { fetchArtObject } from "../redux/actions/artObjectActions";
import strings from "../languages/strings";
import { Link } from "react-router-dom";
import { apiGet } from "../api/api";

import { makeStyles } from "@mui/styles";
import { useModalStore } from "../stores/useModalStore";

import Header from "../components/collections/Header";
import Loader from "../components/utils/Loader";
import MoreOptions from "../components/utils/buttons/MoreOptions";
import GoBack from "../components/utils/buttons/GoBack";
import ImageModal from "../components/utils/ImageModal";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

interface IProps {
  match?: any;
  artObject: IArtObject;
  dispatch: Dispatch<any>;
  isFetching: boolean;
  history: any;
}

const ArtObjectDetail = (props: IProps) => {
  const classes = useStyles();
  const {
    isFetching,
    artObject,
    match: { params },
    dispatch,
  } = props;

  const [imageUrl, setImageUrl] = useState<any>();
  const [fullImageUrl, setFullImageUrl] = useState<any>();
  const [lightBoxOpen, setLightBoxOpen] = useState<boolean>(false);
  const [artObjectList, setArtObjectList] = useState<any>();
  const history = useHistory();

  const navigateToPrevious = () => {
    let arrayOfIds: number[] = [];
    artObjectList.map((object: IArtObject) => {
      arrayOfIds.push(object.id);
    });
    let currentIndex = arrayOfIds.indexOf(artObject.id);
    if (currentIndex === 0) {
      const lastItem = artObjectList[arrayOfIds.length - 1];
      history.push(
        `/app/collections/${lastItem.projectId}/artObjects/${lastItem.id}`
      );
      setImageUrl(undefined);
      return;
    }
    const previous = artObjectList[currentIndex - 1];
    if (!!previous) setImageUrl(undefined);
    history.push(
      `/app/collections/${previous.projectId}/artObjects/${previous.id}`
    );
  };

  const navigateToNext = () => {
    let arrayOfIds: number[] = [];
    artObjectList.map((object: IArtObject) => {
      arrayOfIds.push(object.id);
    });
    let currentIndex = arrayOfIds.indexOf(artObject.id);
    if (currentIndex === arrayOfIds.length - 1) {
      const firstItem = artObjectList[0];
      history.push(
        `/app/collections/${firstItem.projectId}/artObjects/${firstItem.id}`
      );
      setImageUrl(undefined);
      return;
    }
    const next = artObjectList[currentIndex + 1];
    if (!!next) setImageUrl(undefined);
    history.push(`/app/collections/${next.projectId}/artObjects/${next.id}`);
  };

  const handleChangeCollection = (event: any) => {
    event.stopPropagation();
    useModalStore.setState({
      changeCollectionModal: {
        open: true,
        data: { artObjectId: artObject.id, projectId: artObject.projectId },
      },
    });
  };

  const handleOpenDeleteModal = () => {
    useModalStore.setState({
      deleteArtObjectModal: {
        open: true,
        data: { artObjectId: params.artObjectId, projectId: params.projectId },
      },
    });
  };

  useEffect(() => {
    dispatch(fetchArtObject(params.projectId, params.artObjectId));

    if (!imageUrl || imageUrl.length < 5) {
      apiGet(
        `/projects/${params.projectId}/art_objects/${params.artObjectId}/images`,
        false
      )
        .then((response: any) => response.text())
        .then((url) => {
          setImageUrl(url);
        });
    }
    if (!fullImageUrl || fullImageUrl.length < 5) {
      apiGet(
        `/projects/${params.projectId}/art_objects/${params.artObjectId}/images/full`,
        false
      )
        .then((response: any) => response.text())
        .then((url) => {
          setFullImageUrl(url);
        });
    }
  }, [params.projectId, params.artObjectId, dispatch, imageUrl, fullImageUrl]);

  const { isLoading } = useQuery(
    "artObjects",
    async () => {
      return await apiGet(`/projects/${params.projectId}/art_objects`);
    },
    {
      onSuccess: (data) => {
        if (data) {
          setArtObjectList(data);
        }
      },
      refetchOnMount: "always",
    }
  );

  return (
    <div className={classes.root}>
      <Header projectId={params.projectId} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link style={{ color: "black" }} to="/app/collections">
          {strings.collections}
        </Link>
        <Link
          style={{ color: "black" }}
          to={`/app/collections/${params.projectId}/artObjects`}
        >
          {strings.artObject}
        </Link>
        <Link
          to={`/app/collections/${params.projectId}/artObjects/${params.artObjectId}`}
          aria-current="page"
          style={{ color: "black" }}
        >
          {strings.details}
        </Link>
      </Breadcrumbs>
      <div className={classes.content} style={{ marginRight: "3rem" }}>
        {props.artObject && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <GoBack
                label={"Terug naar overzicht kunstwerken"}
                to={`/app/collections/${params.projectId}/artObjects?focus=${props.artObject.id}`}
              />
            </Box>
            <Box>
              <Button
                onClick={() => {
                  props.history.push(
                    `/app/collections/${params.projectId}/artObjects/${props.artObject.id}/edit`
                  );
                }}
                color={"primary"}
                style={{ marginRight: "10px" }}
                data-cy={"edit-artobject-button"}
              >
                Wijzigen
              </Button>
              <MoreOptions
                menuItems={[
                  {
                    label: "Verplaats naar andere collectie",
                    onClick: handleChangeCollection,
                  },
                  {
                    label: "Verwijderen",
                    onClick: handleOpenDeleteModal,
                  },
                ]}
              />
            </Box>
          </Box>
        )}
        {isFetching ? <Loader open={true} /> : ""}
        <Grid container spacing={3}>
          {artObject && (
            <>
              <Grid item xs={12} lg={6}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box>
                    <Button onClick={() => navigateToPrevious()}>
                      Vorig kunstwerk
                    </Button>
                  </Box>
                  <Box>
                    <Button onClick={() => navigateToNext()}>
                      Volgend kunstwerk
                    </Button>
                  </Box>
                </Box>
                <Paper style={{ padding: "2rem" }}>
                  <ArtObjectFields artObject={props.artObject} />{" "}
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ textAlign: "center", marginTop: "2rem" }}
              >
                <img
                  key={artObject.id}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "80vh",
                    cursor: "pointer",
                  }}
                  src={imageUrl}
                  alt={""}
                  onClick={() => setLightBoxOpen(true)}
                />
                <ImageModal
                  open={lightBoxOpen}
                  onClose={() => setLightBoxOpen(false)}
                  imageUrl={fullImageUrl}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    padding: 10,
  },
}));

function mapStateToProps(state: any) {
  const { artObjects } = state;
  const { isFetching, artObject } = artObjects || {
    isFetching: false,
    artObject: null,
  };

  return {
    isFetching,
    artObject,
  };
}

export default connect(mapStateToProps)(ArtObjectDetail);
