import React, { Component } from "react";
import { Card, CardContent } from "@mui/material";
import { withStyles } from "@mui/styles";

import ProjectForm from "../components/collections/ProjectForm";
import strings from "../languages/strings";
import { connect } from "react-redux";
import history from "../history";
import { queryClient } from "../queryClient";

interface IProps {
  classes: any;
  redirect?: any;
}
interface IState {}

class AddProjectPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  onSuccess() {
    // Invalidate query for project overview to make sure it reloads the list
    queryClient.invalidateQueries({ queryKey: ["fetchCollections"] });
    history.push("/app/collections");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <h3 id={"collection-new-title"}>{strings.collection.new}</h3>
        <Card className={classes.content}>
          <CardContent>
            <ProjectForm onSuccess={this.onSuccess} />
          </CardContent>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { collections } = state;
  const { redirect } = collections || {
    redirect: null,
  };

  return {
    redirect,
  };
}

const useStyles = (theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
});

export default connect(mapStateToProps)(withStyles(useStyles)(AddProjectPage));
