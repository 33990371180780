import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { apiGet } from "../../api/api";
import Loader from "../utils/Loader";
import Table, { ImageCell, OptionsCell } from "../utils/table/Table";
import IArtObject from "../../types/artObject";
import { Paper } from "@mui/material";
import history from "../../history";

const ArtObjectsTable = (props: any) => {
  const { projectId } = props;
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState<any>(0);

  useEffect(() => {
    setPage(props.page);
  }, [props]);

  const columns = useMemo(
    () => [
      {
        Header: "Afbeelding",
        accessor: (d: IArtObject) => `${d.id};${d.projectId}`,
        Cell: ImageCell,
        maxWidth: "50px",
        disableGlobalFilter: true,
      },
      {
        Header: "Inventaris nr",
        accessor: "taxationNumber",
      },
      {
        Header: "Titel",
        accessor: "title",
      },
      {
        Header: "Jaartal",
        accessor: "year",
      },
      {
        Header: "Object Groep",
        accessor: "objectType",
        filter: "includes",
      },
        {
            Header: "Object Type",
            accessor: "subObjectType",
            filter: "includes",
        },
        //hidden columns added for searching
        {
            accessor: "comments"
        },
        {
            accessor: "material"
        },
        {
            accessor: "location"
        },
        {
            accessor: "literature"
        },
        {
            accessor: "edition"
        },
        {
            accessor: "collection"
        },
        {
            accessor: "maker"
        },
      {
        Header: " ",
        accessor: (d: IArtObject) => `${d.id};${d.projectId}`,
        Cell: OptionsCell,
        disableGlobalFilter: true,
      },
    ],
    []
  );

    const initialState = { hiddenColumns: ['comments', 'material', 'location', 'literature', 'edition', 'collection', 'maker'] };

    const { isLoading } = useQuery(
    "artObjects",
    async () => {
      return await apiGet(`/projects/${projectId}/art_objects`);
    },
    {
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
      refetchOnMount: "always",
    }
  );


  return (
    <Paper>
      <Loader open={isLoading} />
      <Table
        label={"kunstwerken"}
        initialState={initialState}
        columns={columns}
        data={data}
        search={props.search}
        objectType={props.filter}
        rowClick={(row: any) => {
          history.push(
            `/app/collections/${row.original.projectId}/artObjects?search=${
              props.search
            }&filter=${props.filter?.objectType || ""}&page=${page}`
          );
          history.push(
            `/app/collections/${row.original.projectId}/artObjects/${row.original.id}`
          );
        }}
        onPageSwitch={(pageIndex: number) => {
          setPage(pageIndex);
        }}
        startPage={page}
      />
    </Paper>
  );
};

export default ArtObjectsTable;
