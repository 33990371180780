import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { fetchProject } from "../../api/Projects";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  header: {
    marginBottom: theme.spacing(1)
  }
}));

function Header(props: any) {
  const classes = useStyles();
  const [collection, setCollection] = useState<any>({});

  useQuery("project" + props.projectId, () => fetchProject(props.projectId), {
    onSuccess: data => {
      setCollection(data);
    },
    refetchOnMount: "always"
  });

  return (
    <Box className={classes.header} id={props.id}>
      <Typography variant={"h3"}>
        {collection ? collection.name : " "}
      </Typography>
    </Box>
  );
}

export default Header;
