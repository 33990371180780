import { apiGet } from "./api";
import { useCurrentUserStore } from "../stores/useCurrentUserStore";

const fetchUsers = async () => {
  try {
    return await apiGet(`/users`);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const loadCurrentUser = async () => {
  const data = await apiGet("/users/current");
  useCurrentUserStore.setState({ user: data });
};

export { fetchUsers, loadCurrentUser };
