import { Button, Menu, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import React, { useState } from "react";

interface IMenuItem {
  label: string;
  onClick: (event: any) => void;
}

const MoreOptions = (props: { menuItems: IMenuItem[] }) => {
  const [anchorEl, setAnchorEl] = useState<any>();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} className={"more-options-open"}>
        Meer opties <MoreHoriz style={{ marginLeft: "10px" }} />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {props.menuItems.map((menuItem, index) => (
          <MenuItem
            key={menuItem.label + index}
            id={menuItem.label + index}
            onClick={event => {
              setAnchorEl(null);
              menuItem.onClick(event);
            }}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MoreOptions;
