import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import history from "../history";

const HomeScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid className={classes.quoteContainer} item xs={5} md={5}>
          <div className={classes.quote}> </div>
          <Typography className={classes.namePosition} variant="body1">
            Foto: nalatenschap Hans Bosman (1958-2012)
          </Typography>
        </Grid>
        <Grid className={classes.content} item md={7} xs={7}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <h1>ARTCHIVE</h1>
              <Button variant={"outlined"} onClick={() => history.push(`/app`)}>
                Login
              </Button>
            </div>
            <div className={classes.contentHeader}>
              <h2>Online registratie van kunstwerken</h2>
            </div>
            <div className={classes.contentBody}>
              <Typography>
                Voor kunstenaars en beheerders van nalatenschappen is ARTCHIVE
                ontwikkeld. ARTCHIVE is een gebruiksvriendelijke en betaalbare
                online applicatie om kunstwerken te registreren volgens de
                basisvereisten: inventarisnummer, maker, titel, jaartal,
                techniek, afmetingen, standplaats, afbeelding en aanvullende
                opmerkingen.
              </Typography>
            </div>
            <div className={classes.contentBody}>
              <h2>Eenvoudig en overzichtelijk</h2>
            </div>

            <div className={classes.contentBody}>
              <Typography>
                Met ARTCHIVE maakt u een overzichtelijk en praktisch overzicht
                van een oeuvre of collectie. ARTCHIVE is eenvoudig doorzoekbaar
                op alle ingevoerde informatie. U kunt foto’s van werken
                toevoegen in de door u gewenste bestandsgrootte. Vanuit ARTCHIVE
                kunt u exports maken in pdf-formaat van de gehele collectie of
                van selecties.
              </Typography>
            </div>

            <div className={classes.contentBody}>
              <h2>Betaalbaar en meteen starten</h2>
            </div>
            <div className={classes.contentBody}>
              <Typography>
                Voor meer informatie:{" "}
                <a href={"mailto:info@artchive.nl"}>info@artchive.nl</a> of bel
                06 22849282 <br />
                ARTCHIVE is ontwikkeld door:
                <br /> Miriam Windhausen, Advies kunstenaars-nalatenschappen{" "}
                <br />
                Max Raadgever, IT-specialist.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
    position: "absolute",
  },
  quoteContainer: {},
  quoteContainerSmall: {
    position: "fixed",
    height: "100%",
    width: "100%",
    left: "0px",
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    backgroundImage: "url(/images/loginimage.jpg)",
    opacity: 0.9,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  name: {
    color: theme.palette.grey,
  },
  namePosition: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey,
    position: "absolute" as "absolute",
    bottom: 0,
    left: "42%",
  },
  bio: {
    color: theme.palette.black,
  },
  contentContainer: {},
  content: {
    height: "100%",
    flexDirection: "column" as "column",
  },
  contentHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(5),
    paddingLeft: 80,
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    paddingLeft: 80,
    paddingTop: 20,
    paddingRight: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

export default HomeScreen;
