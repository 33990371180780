import React from "react";
import Loader from "../utils/Loader";
import EmptyState from "../utils/EmptyState";
import strings from "../../languages/strings";
import ProjectListBase from "./ProjectListBase";
import { useQuery } from "react-query";
import { apiGet } from "../../api/api";
import { Box } from "@mui/material";

const ProjectList = ({ search }: any) => {
  const collections = useQuery(["fetchCollections"], fetchcollections);

  return (
    <div>
      {collections.isLoading && (
        <Box>
          <Loader inItem open={true} />
        </Box>
      )}
      {!collections.isLoading && collections.data.length === 0 && (
        <EmptyState text={strings.noCollectionsFound} icon={null} />
      )}
      {collections.data != null && collections.data.length > 0 && (
        <div>
          <ProjectListBase
            collections={collections.data}
            handleClick={() => {}}
            search={search}
          />
        </div>
      )}
    </div>
  );
};

export function fetchcollections() {
  return apiGet(`/projects`)
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.error(err);
    });
}

export default ProjectList;
