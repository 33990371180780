import { Box, Button } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React from "react";

const PaginationControls = ({
  canPreviousPage,
  pageIndex,
  pageCount,
  canNextPage,
  nextPage,
  previousPage,
  onPageSwitch,
}: any) => {
  return (
    <Box paddingRight={2} display={"flex"} justifyContent={"space-between"}>
      <Box>
        <Button
          disabled={!canPreviousPage}
          onClick={() => {
            onPageSwitch(pageIndex - 1);
            previousPage();
          }}
        >
          <ChevronLeft />
        </Button>
      </Box>
      <Box margin={"auto"}>
        {pageIndex + 1} - {pageCount}
      </Box>
      <Box>
        <Button
          disabled={!canNextPage}
          onClick={() => {
            onPageSwitch(pageIndex + 1);
            nextPage();
          }}
        >
          <ChevronRight />
        </Button>
      </Box>
    </Box>
  );
};

export default PaginationControls;
