import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Drawer } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";

import SettingsIcon from "@mui/icons-material/Settings";

import strings from "../../../../languages/strings";

import { SidebarNav } from "./components";
import InputIcon from "@mui/icons-material/Input";
import { useCurrentUserStore } from "../../../../stores/useCurrentUserStore";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const { user } = useCurrentUserStore.getState();

  const pages = [
    {
      title: strings.collections,
      href: "/app/collections",
      icon: <DashboardIcon />,
    },
  ];

  if (user && (user.role === "ADMIN" || user.role === "MANAGER")) {
    pages.push({
      title: "Gebruikers",
      href: "/app/users",
      icon: <SettingsIcon />,
    });
  }

  pages.push(
    {
      id: "settingsButton",
      title: strings.settings,
      href: "/app/settings",
      icon: <SettingsIcon />,
      bottom: true,
    },
    {
      id: "logoutButton",
      title: "Uitloggen",
      href: "/app/logout",
      icon: <InputIcon />,
      bottom: true,
    }
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Profile /> */}
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
