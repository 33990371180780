import {
  REQUEST_TOKEN,
  RECEIVE_TOKEN,
  SIGN_OUT,
  TOKEN_ERROR
} from "../actions/authActions";
import strings from "../../languages/strings";

function auth(
  state = {
    isFetching: false,
    token: ""
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_TOKEN:
      return Object.assign({}, state, {
        isFetching: true,
        error: null
      });
    case RECEIVE_TOKEN:
      return Object.assign({}, state, {
        isFetching: false,
        token: action.token
      });
    case TOKEN_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: strings.loginError
      });
    case SIGN_OUT:
      return Object.assign({}, state, {
        token: null
      });
    default:
      return state;
  }
}

export default auth;
