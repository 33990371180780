import React from "react";
import { Button, Grid } from "@mui/material";
import { TextField } from "mui-rff";
import { makeStyles } from "@mui/styles";
import { Form } from "react-final-form";

interface IProps {
  onSubmit: any;
}

const ProjectFormBase = (props: IProps) => {
  const { onSubmit } = props;
  const classes = useStyles();

  const basicProps = {
    variant: "outlined" as "outlined",
    className: classes.textField,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={() => {
            return null as any;
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                id={"collection-name-text-input"}
                label={"Collectie naam"}
                name="name"
                required
                {...basicProps}
              />

              <Button
                id={"collection-submit"}
                type={"submit"}
                variant="contained"
                color={"primary"}
                fullWidth
              >
                Opslaan
              </Button>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {},
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default ProjectFormBase;
