import React, { useEffect, useState } from "react";
import { apiGet } from "../../../../api/api";
import { Box } from "@mui/material";

function ImageCellRenderer({ projectId, artObjectId }: any) {
  const [imageUrl, setImageUrl] = useState<any>(null);

  useEffect(() => {
    apiGet(`/projects/${projectId}/art_objects/${artObjectId}/images`, false)
      .then((r) => r.text())
      .then((url) => {
        setImageUrl(url);
      });
  }, [projectId, artObjectId]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      height={"100px"}
    >
      {imageUrl !== null ? (
        <Box>
          <img
            style={{ maxWidth: 100, maxHeight: 100, scrollMargin: "100px" }}
            src={imageUrl}
            alt={""}
          />
        </Box>
      ) : (
        <span></span>
      )}
    </Box>
  );
}

function ImageCell({ value }: any) {
  return (
    <ImageCellRenderer
      projectId={value.split(";")[1]}
      artObjectId={value.split(";")[0]}
    />
  );
}

export default ImageCell;
