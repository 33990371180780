import React from "react";
import { Button } from "@mui/material";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import ResetPasswordValidator from "./ResetPasswordValidator";

interface IProps {
  dispatch: any;
  isFetching: boolean;
  errorMessage: string;
  success: boolean;
}

function ResetPasswordForm(props: IProps & any) {
  const basicProps = {
    variant: "standard" as "standard",
    sx: { marginTop: 2 },
  };

  return (
    <Form
      onSubmit={props.handleSubmit}
      initialValues={{}}
      validateOnBlur={false}
      validate={ResetPasswordValidator}
      render={({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            label={"Password"}
            name="password1"
            type={"password"}
            {...basicProps}
          />
          <TextField
            label={"Repeat Password"}
            name="password2"
            type={"password"}
            {...basicProps}
          />

          <Button
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ marginTop: 5 }}
          >
            Wijzig wachtwoord
          </Button>
        </form>
      )}
    />
  );
}

export default ResetPasswordForm;
