import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { apiPost } from "../../api/api";

const ChangePasswordForm = () => {
  const [success, setSuccess] = useState("");

  const basicProps = {
    variant: "outlined" as "outlined",
    sx: { marginTop: 1 },
    required: true,
  };

  const onSubmit = (values: any) => {
    return new Promise((resolve) => {
      setSuccess("");
      apiPost(
        `/users/changepassword`,
        {
          oldPassword: values.passwordOld,
          newPassword: values.passwordNew1,
        },
        false
      )
        .then(() => {
          setSuccess("Wachtwoord gewijzigd");
          resolve(true);
        })
        .catch(() => {
          resolve({ passwordOld: "Wachtwoord incorrect" });
        });
    });
  };

  const validate = (values: any) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const errors: any = {};
    if (!values.passwordOld) {
      errors.passwordOld = "Verplicht";
    }
    if (!values.passwordNew1 || values.passwordNew1.length <= 6) {
      errors.passwordNew1 = "Wachtwoord is te kort (minimaal 7 karakters)";
    } else if (!passwordRegex.exec(values.passwordNew1)) {
      errors.passwordNew1 =
        "Wachtwoord moet een Hoofdletter, kleine letter en een nummer bevatten";
    }

    if (!values.passwordNew2) {
      errors.passwordNew2 = "Verplicht";
    }
    if (values.passwordNew1 && values.passwordNew2) {
      if (values.passwordNew1 !== values.passwordNew2) {
        errors.passwordNew2 = "Wacthwoorden komen niet overeen";
      }
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      validate={validate}
      render={({ handleSubmit, form }) => (
        <form
          noValidate
          onSubmit={async (event) => {
            const response: any = await handleSubmit(event);
            if (response === true) {
              form.restart();
            }
          }}
        >
          <Typography color={"primary"} style={{ marginTop: "1rem" }}>
            {success}
          </Typography>
          <TextField
            label={"Huiding wachtwoord"}
            name="passwordOld"
            type={"password"}
            {...basicProps}
            autoComplete={"new-password"}
          />
          <TextField
            label={"Nieuw wachtwoord"}
            name="passwordNew1"
            type={"password"}
            {...basicProps}
          />
          <TextField
            label={"Herhaal nieuw wachtwoord"}
            name="passwordNew2"
            type={"password"}
            {...basicProps}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ marginTop: 1 }}
          >
            Wijzig wachtwoord
          </Button>
        </form>
      )}
    />
  );
};

export default ChangePasswordForm;
