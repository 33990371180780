import React, { Component } from "react";
import { Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { FolderOpen } from "@mui/icons-material";

interface IProps {
  classes: any;
  text: string;
  icon: any;
}

interface IState {}

class EmptyState extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, text } = this.props;
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.iconWrapper}>
          <FolderOpen className={classes.icon} />
        </Box>
        <Box>
          <Typography className={classes.text}>{text}</Typography>
        </Box>
      </Box>
    );
  }
}

const useStyles = () => ({
  wrapper: {
    marginTop: 200,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    textAlign: "center" as "center"
  },
  iconWrapper: {
    opacity: "0.6"
  },
  icon: {
    width: 100,
    height: 100
  },
  text: {
    fontSize: 20
  }
});

export default withStyles(useStyles)(EmptyState);
