import { combineReducers } from "redux";
import artObjects from "./artObjectReducer";
import auth from "./authReducer";
import collections from "./projectReducer";

const rootReducer = combineReducers({
  artObjects,
  auth,
  collections
});

export default rootReducer;
