import {
  RECEIVE_collections,
  REQUEST_collections,
} from "../actions/projectActions";

function collections(
  state = {
    isFetching: false,
    collections: [],
  },
  action: any
) {
  switch (action.type) {
    case REQUEST_collections:
      return Object.assign({}, state, {
        isFetching: true,
        redirect: null,
      });
    case RECEIVE_collections:
      return Object.assign({}, state, {
        isFetching: false,
        collections: action.collections,
        redirect: null,
      });
  }
  return state;
}

export default collections;
