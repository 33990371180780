import React from "react";
import { useModalStore } from "../stores/useModalStore";
import { Button, DialogContentText } from "@mui/material";
import ModalDialog from "../components/utils/ModalDialog";
import { useMutation } from "react-query";
import { apiDelete } from "../api/api";
import history from "../history";
import { loadCurrentUser } from "../api/Users";

function DeleteArtObjectModal() {
  const {
    deleteArtObjectModal: { open, data }
  } = useModalStore();

  const deleteArtObject = useMutation(
    async (values: any) => {
      await apiDelete(
        `/projects/${data.projectId}/art_objects/${data.artObjectId}`
      );
    },
    {
      onSuccess: () => {
        useModalStore.setState({
          deleteArtObjectModal: { open: false, data: {} }
        });
        history.push(`/app/collections/${data.projectId}/artObjects`);
        loadCurrentUser();
      }
    }
  );

  const close = () => {
    useModalStore.setState({
      deleteArtObjectModal: { open: false, data: {} }
    });
  };

  return (
    <ModalDialog
      open={open}
      onClose={close}
      title={"Kunstwerk verwijderen"}
      actions={
        <>
          <Button onClick={close}>Annuleer</Button>
          <Button onClick={deleteArtObject.mutate} color={"primary"}>
            Verwijderen
          </Button>
        </>
      }
    >
      <DialogContentText>
        Wilt u dit kunstwerk permanent verwijderen?
      </DialogContentText>
    </ModalDialog>
  );
}

export default DeleteArtObjectModal;
