import React, { useState } from "react";
import { Box, Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

interface IProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
}

const ImageModal = ({ open, onClose, imageUrl }: IProps) => {
  const [zoomed, setZoomed] = useState<boolean>(false);

  const closeDialog = () => {
    setZoomed(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullScreen={true}
      onClose={closeDialog}
      hideBackdrop={true}
      PaperProps={{
        style: {
          backgroundColor: "#000000ba",
          boxShadow: "none",
        },
      }}
    >
      <Box
        height={"20px"}
        style={{ opacity: "1" }}
        position={"fixed"}
        right={"20px"}
      >
        <IconButton onClick={closeDialog} style={{ color: "white" }}>
          <Close />
        </IconButton>
      </Box>
      <Box
        width={"100%"}
        height={"calc(100% - 20px)"}
        textAlign={"center"}
        style={{ opacity: "1" }}
      >
        <img
          style={{
            maxWidth: zoomed ? "200%" : "100%",
            maxHeight: zoomed ? "200%" : "100%",
            margin: "auto",
          }}
          src={imageUrl}
          alt={""}
          onClick={() => setZoomed(!zoomed)}
        />
      </Box>
    </Dialog>
  );
};

export default ImageModal;
