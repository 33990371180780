import React, { useState } from "react";
import Wizard from "../utils/Wizard";
import ArtObjectForm from "./Form";
import FileUpload from "../utils/FileUpload";
import ArtObjectOverview from "./Overview";
import IArtObject from "../../types/artObject";

const taxationNumberRegex = /^[a-zA-Z0-9-]*$/;

const ArtObjectWizard = (props: any) => {
  const [artObject, setArtObject] = useState<IArtObject>();
  const [imageFormData, setImageFormData] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [update, setUpdate] = useState<any>(false);

  const handleSubmit = () => {
    if (!update) {
      return props.onSubmit(artObject, -1);
    } else {
      return props.onSubmit(artObject, imageFormData);
    }
  };

  const handleArtObjectChange = (values: any) => {
    setArtObject(values);
  };

  const handleImageChange = (file: any, previewUrl: any) => {
    setImageFormData(file);
    setPreviewUrl(previewUrl);
    setUpdate(true);
  };

  return (
    <Wizard
      onSubmit={handleSubmit}
      onPageSwitch={handleArtObjectChange}
      steps={["Details", "Afbeeldingen", "Controle"]}
      initialValues={props.initialValues}
    >
      <Wizard.Page
        validate={(values: any) => {
          const errors: any = {};
          if (
            values.taxationNumber &&
            !taxationNumberRegex.exec(values.taxationNumber)
          ) {
            errors.taxationNumber = "Alleen letters en cijfers zijn toegestaan";
          }
          return errors;
        }}
      >
        <ArtObjectForm />
      </Wizard.Page>
      <Wizard.Page>
        <FileUpload
          onChange={handleImageChange}
          previewUrl={previewUrl}
          artObject={props.initialValues}
        />
      </Wizard.Page>
      <Wizard.Page>
        <ArtObjectOverview
          imageFormData={imageFormData}
          artObject={artObject}
        />
      </Wizard.Page>
    </Wizard>
  );
};

export default ArtObjectWizard;
