import React, { Fragment } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IProps {
  title: string;
  open: boolean;
  onClose: () => void;
  actions: React.ReactNode;
  children: any;
}
const useStyles = makeStyles(() => ({
  modalContainer: {},
  title: {
    fontSize: 20
  },
  text: {}
}));

const ModalDialog: React.FunctionComponent<IProps> = props => {
  const { open, onClose, title } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <Dialog
        className={classes.modalContainer}
        open={open || false}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent className={classes.text}>{props.children}</DialogContent>
        <DialogActions>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            {props.actions}
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ModalDialog;
