import React from "react";
import { useTokenStore } from "../stores/useTokenStore";
import { Redirect } from "react-router-dom";

const LogoutPage = () => {
  useTokenStore.getState().setTokens({ accessToken: "", refreshToken: "" });

  return (
    <div>
      <Redirect to={"/login"} />
    </div>
  );
};

export default LogoutPage;
