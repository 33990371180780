import React, { useState } from "react";
import ModalDialog from "../components/utils/ModalDialog";
import { useModalStore } from "../stores/useModalStore";
import { Button, DialogContentText } from "@mui/material";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { apiPost } from "../api/api";
import { useMutation } from "react-query";
import Loader from "../components/utils/Loader";

const AddUserModal = () => {
  const {
    addUserModal: { open },
  } = useModalStore();

  const close = () => {
    useModalStore.setState({
      addUserModal: { open: false, data: {} },
    });
    setSuccess(false);
  };

  const [success, setSuccess] = useState(false);

  const onSubmit = useMutation(
    async (values: any) => {
      await apiPost(`/users/new`, values);
    },
    {
      onSuccess: () => {
        setSuccess(true);
      },
    }
  );

  return (
    <ModalDialog
      open={open}
      onClose={close}
      title={"Gebruiker toevoegen"}
      actions={<></>}
    >
      <Loader open={onSubmit.isLoading} />
      {success ? (
        <>
          <DialogContentText>De gebruiker is toegevoegd.</DialogContentText>
          <div
            style={{
              marginTop: "12px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={close}>Sluiten</Button>
          </div>
        </>
      ) : (
        <>
          <DialogContentText>
            Voeg een gebruiker toe. De nieuwe gebruiker krijgt een e-mail met
            inloggegevens en een link om in te loggen
          </DialogContentText>
          <Form
            onSubmit={(values) => {
              onSubmit.mutate(values);
            }}
            initialValues={{}}
            validate={(values: any) => {
              const errors: any = {};
              if (!values.username || values.username === "") {
                errors.username = "E-mail is verplicht";
              }
              return errors;
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  label={"E-mail"}
                  name="username"
                  required={true}
                  data-cy={"email"}
                  variant={"outlined"}
                  sx={{ marginTop: 2 }}
                />
                <div
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button onClick={close}>Annuleer</Button>
                  <Button
                    id={"collection-submit"}
                    type={"submit"}
                    variant="contained"
                    color={"primary"}
                  >
                    Toevoegen
                  </Button>
                </div>
              </form>
            )}
          />
        </>
      )}
    </ModalDialog>
  );
};

export default AddUserModal;
