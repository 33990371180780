import ChangeCollectionModal from "./ChangeCollectionModal";
import React from "react";
import ExportArtObjectModal from "./ExportArtObjectModal";
import DeleteArtObjectModal from "./DeleteArtObjectModal";
import UpgradeAccountModal from "./UpgradeAccountModal";
import AddUserModal from "./AddUserModal";

/**
 * All modals used in the application are defined here.
 * You can open and close them by using the useModalStore, every modal has an entry in there with
 * a boolean named open.
 * There is also the option to pass data to the modal with the data property, please check the needed moda
 * for the possible data that it needs.
 */
const Modals = () => {
  return (
    <>
      <ChangeCollectionModal />
      <ExportArtObjectModal />
      <DeleteArtObjectModal />
      <UpgradeAccountModal />
      <AddUserModal />
    </>
  );
};

export default Modals;
