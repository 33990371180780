function ResetPasswordValidator(values: any) {
  const errors: any = {};

  if (!values.password1) {
    errors.password1 = true;
  }
  if (!values.password2) {
    errors.password2 = true;
  }
  if (values.password1 !== values.password2) {
    errors.password1 = "Passwords do not match";
    errors.password2 = true;
  }
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (!values.password1 || values.password1.length <= 6) {
    errors.password1 = "Password should be at least 6 characters long";
  } else if (!passwordRegex.exec(values.password1)) {
    errors.password1 =
      "Password should contain at least 1 number and special character";
  }

  return errors;
}

export default ResetPasswordValidator;
