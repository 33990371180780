import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import IArtObject from "../../types/artObject";
import strings from "../../languages/strings";
import SizeDisplay from "./SizeDisplay";

interface IProps {
  artObject: IArtObject;
}

const ArtObjectFields = (props: IProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={10} data-cy={"title"}>
        <Typography variant={"h4"}>{props.artObject.title}</Typography>
      </Grid>
      <Grid item xs={2} data-cy={"taxationNumber"}>
        {props.artObject.taxationNumber}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.maker}:
      </Grid>
      <Grid item xs={7} data-cy={"maker"}>
        <Box display={"flex"} flexDirection={"row"} margin={"auto"}>
          {props.artObject.maker}{" "}
          {props.artObject.maker === props.artObject.defaultMaker && (
            <a
              href="#"
              onClick={() => window.open(props.artObject.defaultMakerRkdLink)}
              style={{
                marginLeft: "20px",
                textDecoration: "underline",
              }}
            >
              RKD link
            </a>
          )}
        </Box>
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.year}:
      </Grid>
      <Grid item xs={7} data-cy={"year"}>
        {props.artObject.year}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.objectType}:
      </Grid>
      <Grid item xs={7} data-cy={"objectType"}>
        {props.artObject.objectType}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.subObjectType}:
      </Grid>
      <Grid item xs={7} data-cy={"subObjectType"}>
        {props.artObject.subObjectType}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.material}:
      </Grid>
      <Grid item xs={7} data-cy={"material"}>
        {props.artObject.material}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.location}:
      </Grid>
      <Grid item xs={7} data-cy={"location"}>
        {props.artObject.location}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.sizeWithoutFrame}:
      </Grid>
      <Grid item xs={7} data-cy={"sizeWithoutFrame"}>
        {props.artObject.sizeWithoutFrame || (
          <SizeDisplay artObject={props.artObject} withFrame={false} />
        )}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.frame}:
      </Grid>
      <Grid item xs={7} data-cy={"frame"}>
        {props.artObject.frame ? "Ja" : "Nee"}
      </Grid>
      {props.artObject.frame && (
        <>
          <Grid item xs={5}>
            {strings.artObjectFields.sizeWithFrame}:
          </Grid>
          <Grid item xs={7} data-cy={"sizeWithFrame"}>
            {props.artObject.sizeWithFrame || (
              <SizeDisplay artObject={props.artObject} withFrame={true} />
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.collection}:
      </Grid>
      <Grid item xs={7} data-cy={"collection"}>
        {props.artObject.collection}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.edition}:
      </Grid>
      <Grid item xs={7} data-cy={"edition"}>
        {props.artObject.edition}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.literature}:
      </Grid>
      <Grid item xs={7} data-cy={"literature"}>
        {props.artObject.literature &&
          props.artObject.literature.map((literature: any, index: number) => (
            <Box key={index}>{literature.label}</Box>
          ))}
      </Grid>
      <Grid item xs={5}>
        {strings.artObjectFields.comments}:
      </Grid>
      <Grid item xs={7} data-cy={"comments"} style={{ whiteSpace: "pre-line" }}>
        {props.artObject.comments}
      </Grid>
    </Grid>
  );
};

export default ArtObjectFields;
